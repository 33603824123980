import {
  Box,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Menu,
  Modal,
  Grid,
  Collapse,
  TablePagination,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import copyTextToClipboard from "copy-text-to-clipboard";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link } from "react-router-dom";
import Svg from "../assets/Svg";
import Png from "../assets/Png";
import Jpg from "../assets/Jpg";
import Video from "../assets/Video";
import Sidebar from "../component/admin/defaultLayout/Sidebar";
import Header from "../component/admin/defaultLayout/Header";
import PaidLable from "../common/PaidLable";
import FailedLable from "../common/FailedLable";
import PendingLable from "../common/PendingLable";
import PersonIcon from "@mui/icons-material/Person";
import PrimaryButton from "../common/PrimaryButton";
import AuthVideo from "../common/AuthVideo";
import GreyButton from "../common/GreyButton";
import TextButton from "../common/TextButton";
import PrimaryBorderButton from "../common/PrimaryBorderButton";
import OutlineButton from "../common/OutlineButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  validateName,
  validatePassword,
  validateEmail,
  validateConfirmPassword,
} from "../validation/AuthValidation";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import SettingsIcon from "@mui/icons-material/Settings";
import PercentIcon from "@mui/icons-material/Percent";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SendIcon from "@mui/icons-material/Send";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Settings from "@mui/icons-material/Settings";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  ReplaceCharsWithAsterisks,
  sortInAscending,
  sortInDescending,
  sortFieldInAscending,
  sortFieldInDescending,
  IdentifyAddressProof,
  validateInpField,
} from "../common/CommonFun";
import { Export_excel } from "../common/ExportAll";
import { IOSSwitch } from "../common/IosSwitch";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { NetworkLink, selectNetwork } from "../common/NetworkLink";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ErrorShow from "../common/ErrorShow";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import MuiPhoneInput from "material-ui-phone-number";
import VerifiedIcon from "@mui/icons-material/Verified";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import TokenPreSaleProgress from "../common/TokenPreSaleProgress";
import { BpCheckbox } from "../common/BpCheckBox";
import MiningProgress from "../common/miningCounter/MiningProgress";

export default {
  BpCheckbox,
  NetworkLink,
  PercentIcon,
  SettingsIcon,
  MoreHorizIcon,
  sortInAscending,
  MuiPhoneInput,
  toast,
  ToastContainer,
  VerifiedIcon,
  HowToRegIcon,
  useNavigate,
  useDispatch,
  useSelector,
  useLocation,
  sortInDescending,
  sortFieldInAscending,
  sortFieldInDescending,
  AdapterDayjs,
  LocalizationProvider,
  DesktopDatePicker,
  DatePicker,
  copyTextToClipboard,
  ReplaceCharsWithAsterisks,
  Stack,
  DialogTitle,
  DialogContent,
  DialogContentText,
  SendIcon,
  ExpandLess,
  ExpandMore,
  Collapse,
  Modal,
  DialogActions,
  MoreVertIcon,
  AddIcon,
  DataGrid,
  Grid,
  Dialog,
  ExpandMoreIcon,
  AccordionDetails,
  Tooltip,
  AccordionSummary,
  Accordion,
  PersonIcon,
  validateName,
  ContentCopyIcon,
  OutlineButton,
  validatePassword,
  validateEmail,
  validateConfirmPassword,
  Box,
  FormHelperText,
  TextField,
  Typography,
  MailOutlineIcon,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  VisibilityOff,
  Visibility,
  Link,
  List,
  ListItem,
  Svg,
  Png,
  Video,
  Sidebar,
  Header,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  PaidLable,
  FailedLable,
  PendingLable,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Menu,
  AuthVideo,
  PrimaryButton,
  GreyButton,
  TextButton,
  PrimaryBorderButton,
  Jpg,
  TablePagination,
  Export_excel,
  moment,
  IOSSwitch,
  CKEditor,
  ClassicEditor,
  ErrorShow,
  selectNetwork,
  IdentifyAddressProof,
  validateInpField,
  Settings,
  TokenPreSaleProgress,
  MiningProgress,
};
