import React, { useState } from "react";
import Index from "../../../Index";
import discordIcon from "../../../../assets/images/png/discordIcon.png";
import instagramIcon from "../../../../assets/images/png/instagram.png";
import youtubeIcon from "../../../../assets/images/png/youtube.png";
import twitterIcon from "../../../../assets/images/png/twitter.png";
import facebookIcon from "../../../../assets/images/jpg/facebook.jpg";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useEffect } from "react";

const Social = () => {
  const details = useSelector((store) => store?.admin?.userDetail);
  let userDetails = JSON?.parse(window?.atob(details));
  const token = userDetails?.token;
  const [value, setValue] = useState("instagram");
  const [link, setLink] = useState({});
  const [userId, setUserId] = useState({});
  console.log(userId, 16);

  const [userName, setUserName] = useState({});
  console.log(userName, 2245421);
  console.log(link, 2245422);

  const [mediaList, setMediaList] = useState([]);
  const [validationError, setValidationError] = useState({});
  console.log(validationError, 29);
  // validation
  const [userNameValidation, setUserNameValidation] = useState("");
  const [linkValidation, setLinkValidation] = useState("");
  function isValidInstagramLink(link) {
    return link.startsWith("https://www.instagram.com/");
  }
  function isValidFacebookLink(link) {
    return link.startsWith("https://www.facebook.com/");
  }
  const handleChangeInput = (value, tag, key) => {
    console.log(value, tag, key, 26);
    if (key == "user_name") {
      let obj = { ...userName };
      if (obj.hasOwnProperty(`${tag}_user_name`)) {
        Object.keys(userName).forEach((item) => {
          if (item == `${tag}_user_name`) {
            obj[item] = value;
          }
        });
      } else {
        obj = { ...userName, [`${tag}_user_name`]: value };
      }

      console.log(obj, 39);
      setUserName(obj);
    } else {
      let obj = { ...link };
      if (obj.hasOwnProperty(`${tag}_link`)) {
        Object.keys(link).forEach((item) => {
          if (item == `${tag}_link`) {
            obj[item] = value;
          }
        });
      } else {
        obj = { ...link, [`${tag}_link`]: value };
      }

      console.log(obj, "11111111");
      setLink(obj);
    }
  };
  const userData = async () => {
    await DataService(token)
      .get(API.User.GET_USER)
      .then((res) => {
        console.log(res?.data?.data, 56);
        setUserId(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    // setLink("");
    // setValidationError("");
  };
  const validateLink = () => {
    const errors = {};
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    let data = {};
    const validationError = {};
    setValidationError(validationError);
    if (value === "instagram") {
      if (!userName?.instagram_user_name) {
        validationError.userName = "User name is required";
      }
      if (!link?.instagram_link) {
        validationError.link = "URL link is required";
      }
      // else if (!isValidInstagramLink(link?.instagram_link)) {
      //   validationError.link = "Invalid Instagram link format";
      // }
      data = {
        user_id: userId?._id,
        instagram_user_name: userName?.instagram_user_name,
        instagram_link: link?.instagram_link,
      };
    } else if (value === "facebook") {
      if (!userName?.facebook_user_name) {
        validationError.userName = "User name is required";
      }
      if (!link?.facebook_link) {
        validationError.link = "URL link is required";
      }

      data = {
        user_id: userId?._id,
        facebook_user_name: userName?.facebook_user_name,
        facebook_link: link?.facebook_link,
      };
    } else if (value === "twitter") {
      if (!userName?.twitter_user_name) {
        validationError.userName = "User name is required";
      }
      if (!link?.twitter_link) {
        validationError.link = "URL link is required";
      }

      data = {
        user_id: userId?._id,
        twitter_user_name: userName?.twitter_user_name,
        twitter_link: link?.twitter_link,
      };
    } else if (value === "youtube") {
      if (!userName?.youtube_user_name) {
        validationError.userName = "User name is required";
      }
      if (!link?.youtube_link) {
        validationError.link = "URL link is required";
      }
      data = {
        user_id: userId?._id,
        youtube_user_name: userName?.youtube_user_name,
        youtube_link: link?.youtube_link,
      };
    }
    // else {
    //   data = {
    //     discord_user_name: userName,
    //     discord_link: link,
    //   };
    // }

    if (Object.keys(validationError).length === 0) {
      try {
        const res = await DataService(token).post(
          API.User.POST_SOCIAL_MEDIA,
          data
        );
        toast.success(res?.data?.message);
        console.log(res);
      } catch (error) {
        console.error(error);
      }
      console.log(data, 149);
    } else {
      console.log(validationError, 168);
    }
  };
  const getUserSocialMedia = async () => {
    await DataService(token)
      .get(API.User.GET_SOCIAL_MEDIA_USER)
      .then((res) => {
        console.log(res?.data?.data, 100);
        setUserName(res?.data?.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getUserSocialLink = async () => {
    await DataService(token)
      .get(API.User.GET_SOCIAL_MEDIA_LINK)
      .then((res) => {
        console.log(res?.data?.data, 127);
        setLink(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getUserSocialList = async () => {
    await DataService(token)
      .get(API.User.GET_SOCIAL_MEDIA_USER_LIST)
      .then((res) => {
        console.log(res?.data?.data, 126);
        setMediaList(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getUserSocialMedia();
    getUserSocialList();
    getUserSocialLink();
    userData();
  }, []);
  return (
    <>
      <Index.Box className="bg-white">
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.TabContext value={value}>
                  <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Index.TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      className="social-tablist"
                    >
                      <Index.Tab
                        icon={
                          <img
                            src={instagramIcon}
                            alt="Discord"
                            className="instagram-icon"
                          />
                        }
                        value="instagram"
                        iconPosition="start"
                        label={<span className="tab-label">Instagram</span>}
                      />
                      <Index.Tab
                        icon={
                          <img
                            src={facebookIcon}
                            alt="Discord"
                            className="facebook-icon"
                          />
                        }
                        value="facebook"
                        iconPosition="start"
                        label={<span className="tab-label">Facebook</span>}
                      />
                      <Index.Tab
                        icon={
                          <img
                            src={youtubeIcon}
                            alt="Discord"
                            className="youtube-icon"
                          />
                        }
                        value="youtube"
                        iconPosition="start"
                        label={<span className="tab-label">Youtube</span>}
                      />

                      {/* <Index.Tab
                        icon={
                          <img
                            src={discordIcon}
                            alt="Discord"
                            className="discord-icon"
                          />
                        }
                        iconPosition="start"
                        value="Discord"
                        label={<span className="tab-label">Discord</span>}
                      /> */}
                      <Index.Tab
                        icon={
                          <img
                            src={twitterIcon}
                            alt="Discord"
                            className="twitter-icon"
                          />
                        }
                        iconPosition="start"
                        value="twitter"
                        label={<span className="tab-label">Twitter</span>}
                      />
                    </Index.TabList>
                  </Index.Box>
                  {/* <Index.TabPanel value="Instagram"> */}
                  <Index.Box className="sub-tab-box">
                    <Index.Typography className="social-text">
                      Instagram is a website which allows users, who sign-up for
                      free profiles, to connect with friends, work colleagues or
                      people they don't know, online
                    </Index.Typography>
                    <Index.Box className="form-group" mb={2}>
                      <Index.TextField
                        fullWidth
                        id=""
                        className="form-control hash-key-input"
                        name="userName"
                        value={userName?.[`${value}_user_name`] || ""}
                        onChange={(e) => {
                          handleChangeInput(e.target.value, value, "user_name");
                        }}
                        placeholder="User name"
                      />
                      {validationError?.userName && (
                        <div className="error-text">
                          {validationError?.userName}
                        </div>
                      )}
                    </Index.Box>

                    <Index.Box className="form-group" mb={2}>
                      <Index.TextField
                        fullWidth
                        id=""
                        className="form-control hash-key-input"
                        name="link"
                        value={link?.[`${value}_link`] || ""}
                        onChange={(e) => {
                          handleChangeInput(e.target.value, value, "link");
                        }}
                        type="text"
                        placeholder="Post URL"
                      />
                      {validationError?.link && (
                        <div className="error-text">
                          {validationError?.link}
                        </div>
                      )}
                    </Index.Box>

                    <Index.Box className="stack-box-btn">
                      <Index.Box className="dashboard-content referral-list-tree-btn">
                        <Index.Typography></Index.Typography>
                      </Index.Box>
                      <Index.Box className="btn-main login-btn-main primary-btn-main-comman">
                        <Index.Button
                          className="add-user-btn btn-dark-comman-main bg-gradiant social-submit"
                          style={{
                            textDecoration: "none",
                            fontSize: 15,
                            fontFamily: "Poppins-SemiBold",
                          }}
                          tabindex="0"
                          type="button"
                          onClick={handleSubmit}
                        >
                          Submit For Review <span class=""></span>
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.TabContext>
              </Index.Box>
            </Index.Box>
            <Index.Box className="dashboard-content referral-list-tree-btn">
              <Index.Typography
                className="admin-page-title res-referral-list-tree-btn"
                component="h2"
                variant="h2"
              >
                {" "}
                Details
              </Index.Typography>
            </Index.Box>
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="">
                      <Index.Box className="page-table-main my_income-table-main table-design-main ">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                            className="table"
                          >
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Date
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="right"
                                >
                                  Status
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="right"
                                >
                                  Link
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {mediaList?.length > 0 ? (
                                mediaList?.map((row) => {
                                  return (
                                    <Index.TableRow
                                      key={row._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        {row?.createdAt
                                          ? Index.moment(row?.createdAt).format(
                                              "DD-MM-YYYY HH:mm:ss"
                                            )
                                          : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        {row?.status ? row?.status : ""}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        <Index.Box className="table-social">
                                          <Index.Box
                                            className={`table-social-main ${
                                              row?.facebook_link ? "active" : ""
                                            }`}
                                          >
                                            <img
                                              src={facebookIcon}
                                              className="table-social-ic"
                                            />
                                            <img
                                              src={Index.Png.Checked}
                                              className="table-social-check"
                                              alt="check"
                                            />
                                          </Index.Box>

                                          <Index.Box
                                            className={`table-social-main ${
                                              row?.instagram_link
                                                ? "active"
                                                : ""
                                            }`}
                                          >
                                            <img
                                              src={instagramIcon}
                                              className="table-social-ic"
                                            />
                                            <img
                                              src={Index.Png.Checked}
                                              className="table-social-check"
                                              alt="check"
                                            />
                                          </Index.Box>

                                          <Index.Box
                                            className={`table-social-main ${
                                              row?.youtube_link ? "active" : ""
                                            }`}
                                          >
                                            <img
                                              src={youtubeIcon}
                                              className="table-social-ic"
                                            />
                                            <img
                                              src={Index.Png.Checked}
                                              className="table-social-check"
                                              alt="check"
                                            />
                                          </Index.Box>
                                          <Index.Box
                                            className={`table-social-main ${
                                              row?.twitter_link ? "active" : ""
                                            }`}
                                          >
                                            <img
                                              src={twitterIcon}
                                              className="table-social-ic"
                                            />
                                            <img
                                              src={Index.Png.Checked}
                                              className="table-social-check"
                                              alt="check"
                                            />
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  );
                                })
                              ) : (
                                <Index.TableRow>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    colSpan={5}
                                  >
                                    No Record found
                                  </Index.TableCell>
                                </Index.TableRow>
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>{" "}
                    <Index.Box className="pagination-main">
                      {/* <Index.TablePagination
                          component="div"
                          page={page}
                          count={count}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          rowsPerPageOptions={[10, 15, 20]}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        /> */}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Social;
