import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";

export const LoginAdmin = createAsyncThunk("admin/loginAdmin", async (data) => {
  const response = await DataService().post(API.Admin.ADMIN_LOGIN, data);
  return response.data;
});

export const registerAdmin = createAsyncThunk(
  "users/registerAdmin",
  async (data) => {
    const response = await DataService().post(API.Admin.USER_REGISTER, data);
    return response.data;
  }
);

export const forgotPasswordAdmin = createAsyncThunk(
  "users/forgotPasswordAdmin",
  async (data) => {
    const response = await DataService().post(
      API.User.UserForgotPassword,
      data
    );
    return response.data;
  }
);

export const getUser = createAsyncThunk("users/getUser", async () => {
  const response = await DataService().post(
    API.Admin.GET_USER_LIST,
    {},
    (Headers = {
      auth: localStorage.getItem("token"),
    })
  );
  return response.data;
});

export const getPresaleDetails = createAsyncThunk(
  "admin/getPrivaeSale",
  async (token) => {
    const response = await DataService(token).get(
      API.Admin.GET_PRIVATE_SALE_LIST
    );
    return response.data;
  }
);

export const LoginUser = createAsyncThunk("users/login", async (data) => {
  let response = await DataService().post(API.User.USER_LOGIN, data);
  return response.data;
});

export const getWalletDataList = createAsyncThunk(
  "users/getWalletDataList",
  async (data) => {
    const response = await DataService(data).get(API.Exchange.GET_WALLET);
    return response.data;
  }
);


