import React from "react";
import Index from "../container/Index";

export default function GreenButton(props) {
  return (
    <>
      <Index.Box className="green-btn-main">
        <Index.Button
          className={props.className}
          onClick={props.onClick}
          disabled={props.disabled}
        >
          {props.btnLabel}
        </Index.Button>
      </Index.Box>
    </>
  );
}
