import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { useSelector } from "react-redux";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import Loader from "../../../../common/Loader";

const ExchangeTransferList = () => {
  const AdminToken = useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);
  const [exchangeTransfer, setExchangeTransfer] = useState([]);
  const [filterData, setFilterData] = useState([]);
  //   pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [count, setCount] = useState(0);
  const [loader, setLoader] = useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    const searchValue = event?.target?.value.toLowerCase();
    const result = exchangeTransfer.filter((item) => {
      let type = item?.type?.toLowerCase()?.toString()?.includes(searchValue);
      let sender = item?.sender?.user_name
        ?.toLowerCase()
        ?.toString()
        ?.includes(searchValue);
      let receiver = item?.receiver?.user_name
        ?.toLowerCase()
        ?.toString()
        ?.includes(searchValue);
      let token = item?.token?.toLowerCase()?.toString()?.includes(searchValue);

      let date = Index.moment(item?.createdAt)
        .format("DD-MM-YYYY hh:mm A")
        .toString()
        ?.includes(searchValue);
      const amount = item?.amount?.toString()?.includes(searchValue);

      return date || type || sender || receiver || token || amount;
    });

    setFilterData(result);
    setPage(0);
  };

  const getExchangeTransfer = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("pageNumber", page + 1);
    urlencoded.append("pageSize", rowsPerPage);
    setLoader(true);
    await DataService(token)
      .post(API.Admin.GET_EXCHANGE_WITHDRAW, urlencoded)
      .then((res) => {
        setExchangeTransfer(res?.data?.data?.withdrawList);
        setFilterData(res?.data?.data?.withdrawList);
        setCount(res?.data?.data?.countOfWithdraw);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
    setLoader(false);
  };

  useEffect(() => {
    getExchangeTransfer();
  }, [page, rowsPerPage]);

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <Index.Box class="bg-white">
          <Index.Box className="dashboard-content referral-list-tree-btn">
            <Index.Typography
              className="admin-page-title res-referral-list-tree-btn"
              component="h2"
              variant="h2"
            >
              {" "}
              Exchange Transfer
            </Index.Typography>
          </Index.Box>
          <Index.Box className="">
            <Index.Box className="transaction-right refferal-search-right">
              {/* <Index.Box className="preasale-search">
                <Index.Box className="user-search-main cus-search">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Search..."
                        // value={searchVal}
                        onChange={(e) => handleSearch(e)}
                      />

                      <img
                        src={Index.Svg.greysearch}
                        className="search-grey-img"
                        alt="search grey img"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box> */}
            </Index.Box>

            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="">
                      <Index.Box className="page-table-main user-withdraw-list">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                            className="table"
                          >
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Date
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  {"Sender --> Receiver"}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Type
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="right"
                                >
                                  Send
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Receive
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Fees
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {filterData?.length > 0 ? (
                                filterData.map((row, index) => {
                                  return (
                                    <Index.TableRow
                                      key={row?._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        {row?.createdAt
                                          ? Index.moment(row?.createdAt).format(
                                              "DD-MM-YYYY HH:mm:ss"
                                            )
                                          : "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="right"
                                      >
                                        {row?.sender
                                          ? row?.sender?.user_name
                                          : "-"}{" "}
                                        {"-->"}{" "}
                                        {row?.receiver
                                          ? row?.receiver?.user_name
                                          : "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="right "
                                      >
                                        {row?.type
                                        ? row?.type === "SelfExchange"
                                          ? "Network to exchange (Self)"
                                          : row?.type === "OthersExchange"
                                          ? "Network to exchange (Other)"
                                          : row?.type === "Others"
                                          ? "Network to network (Other)"
                                          : "-"
                                        : "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="right "
                                      >
                                        {row?.amount
                                          ? row?.amount?.toFixed(6)
                                          : 0}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="right "
                                      >
                                        {row?.token_value
                                          ? row?.token_value?.toFixed(6)
                                          : 0}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="right "
                                      >
                                        {row?.withdraw_fees
                                          ? row?.withdraw_fees?.toFixed(6)
                                          : 0}
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  );
                                })
                              ) : (
                                <Index.TableRow>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    colSpan={5}
                                  >
                                    No Record found
                                  </Index.TableCell>
                                </Index.TableRow>
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>{" "}
                    <Index.Box className="pagination-main">
                      <Index.TablePagination
                        component="div"
                        page={page}
                        count={count}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[15, 25, 50]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelDisplayedRows={(page) =>
                          `Records ${page.from} to ${
                            page.to === -1 ? page.count : page.to
                          } of ${page.count}`
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </div>
  );
};

export default ExchangeTransferList;
