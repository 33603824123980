import message from "../validation/ValidationMessage.json";
import PhoneNumber from "libphonenumber-js";
export const validatePassword = (password, confirmPassword) => {
  const PWD_REGEX =
    /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,16}$/;

  if (password == "") {
    return message.ENTER_NEW_PASSWORD;
  } else if (password.length < 8 || password.length > 16) {
    return message.PASSWORD_LENGTH;
  } else if (!PWD_REGEX.test(password)) {
    return message.PASSWORD_VALIDATION;
  } else {
    return "";
  }
};

export const validateNewPassword = (password, confirmPassword) => {
  const PWD_REGEX =
    /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,16}$/;

  if (password == "") {
    return message.ENTER_NEW_PASSWORD;
  } else if (password.length < 8 || password.length > 16) {
    return message.PASSWORD_LENGTH;
  } else if (!PWD_REGEX.test(password)) {
    return message.PASSWORD_VALIDATION;
  } else {
    return "";
  }
};

export const validatOldPassword = (password, confirmPassword) => {
  const PWD_REGEX =
    /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,16}$/;

  if (password == "") {
    return message.OLD_PASSWORD;
  } else {
    return "";
  }
};
export const checkPassword = (password, confirmPassword) => {
  // const num = /.*[0-9].*/.test(password);
  // let firstChar = password?.substring(0, 1);
  // let checkSpecial = /[@#%&$()^~{}]+/.test(password);
  // const isValid = /[a-z]/.test(password);

  // if (
  //   firstChar !== firstChar?.toUpperCase() ||
  //   !isNaN(firstChar) ||
  //   /[*@!#%&$()^`~{}]+/.test(firstChar) !== false
  // ) {
  //   return message.UPPERCASE;
  // } else if (!isValid) {
  //   return message.LOWER_CASE_CHARACTER;
  // } else if (!checkSpecial) {
  //   return message.SPECIAL_CHARACTER;
  // } else if (!num) {
  //   return message.AT_LEAST_DIGIT;
  // } else if (password.length < 6) {
  //   return message.USER_NAME_LENGTH;
  // } else {
  //   return "";
  // }
  const PWD_REGEX =
    /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,16}$/;

  if (password == "") {
    return message.ENTER_PASSWORD;
  } else {
    return "";
  }
};

export const validateEmail = (email) => {
  const check =
    /^[a-zA-Z0-9]([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z]{2,4})+$/.test(
      email
    );
  if (!email) {
    return message.ENTER_EMAIL;
  } else if (!check) {
    return message.VALID_EMAIL_ADDRESS;
  } else {
    return "";
  }
};
export const validateTokenCode = (token) => {
  if (!token) {
    return "please enter a token";
  } else {
    return "";
  }
};
export const validateImage = (file) => {
  if (!file.type.startsWith("image/")) {
    return "Please select a valid image file (e.g., JPG, PNG, GIF).";
  }

  // Check file size (in bytes)
  const maxSizeInBytes = 102400; // 5MB (adjust as needed)
  if (file.size > maxSizeInBytes) {
    return "The selected image is too large. Please choose a smaller image.";
  } else {
    return "";
  }
};
export const validateFirstName = (firstname) => {
  const letters = /^[a-zA-Z\s]+$/.test(firstname);

  if (!firstname) {
    return message.ENTER_FIRSTNAME;
  } else if (!letters) {
    return message.VALID_FIRSTNAME;
  } else if (firstname.length < 2) {
    return message.MIN_FIRSTNAME_CHAR;
  } else if (firstname.length > 20) {
    return message.MIN_FIRSTNAME_CHAR;
  } else {
    return "";
  }
};
export const validateLastName = (lastname) => {
  const letters = /^[a-zA-Z\s]+$/.test(lastname);
  if (!lastname) {
    return message.ENTER_LASTNAME;
  } else if (!letters) {
    return message.VALID_LASTNAME;
  } else if (lastname.length < 2) {
    return message.MIN_LASTNAME_CHAR;
  } else if (lastname.length > 20) {
    return message.MIN_LASTNAME_CHAR;
  } else {
    return "";
  }
};

export const validateMobileNo = (mobileNo) => {
  
  try {
    const parsedPhoneNumber = PhoneNumber(mobileNo);

    if (!parsedPhoneNumber.isValid()) {
      return message.ENTER_VALID_MOBILE_NO;
    }

    // if (/(\d)\1{3,}/.test(parsedPhoneNumber.number)) {
    //   return "Phone number cannot have continuous single numbers";
    // }

    return "";
  } catch (error) {
    return message.ENTER_MOBILE_NO;
  }
};

export const validateAffilateCode = (affilateCode) => {
  if (!affilateCode) {
    return message.ENTER_AFFILATECODE;
  } else {
    return "";
  }
};

export const validateName = (condition) => {
  const letters =
    /^(?![0-9]+$)(?![!@#$%^&*()_+<>?]+$)[a-zA-Z0-9!@#$%^&*()_+<>?]+$/.test(
      condition
    );
  if (!condition) {
    return message.ENTER_USERNAME;
  } else if (!letters) {
    return message.VALID_USERNAME;
  } else if (condition.length < 2) {
    return message.MIN_CHAR;
  } else {
    return "";
  }
};
export const validateCheckedError = (isChecked) => {
  if (isChecked === false) {
    return message.IS_CHECKED;
  } else {
    return "";
  }
};
export const validateCountry = (country) => {
  if (!country) {
    return message.SELECT_COUNTRY;
  } else {
    return "";
  }
};

export const validateRoundNo = (no) => {
  if (!no) {
    return message.ENTER_ROUND_NO;
  } else if (no > 999) {
    return message.MAXMIMUM_ROUND_NO;
  } else {
    return "";
  }
};
export const validatePaymentMethod = (method) => {
  if (!method) {
    return "Please enter payment method";
  } else if (method.length > 40) {
    return "Payment method cannot be more than 40";
  } else {
    return "";
  }
};
export const validateMinimumPurchase = (purchase) => {
  if (!purchase) {
    return "Please enter minimum purchase";
  } else if (purchase > 9999999999) {
    return "Minimum purchase must be in only 12 digits";
  } else {
    return "";
  }
};

export const validateMaximumPurchase = (purchase) => {
  if (!purchase) {
    return "Please enter maximum purchase";
  }
  //  else if (purchase > 9999999999) {
  //   return "Minimum purchase must be in only 12 digits";
  // }
  else {
    return "";
  }
};

// export const validateTokenQuantity = (purchase) => {
//   if (!purchase) {
//     return "Please enter maximum purchase";
//   }
//   //  else if (purchase > 9999999999) {
//   //   return "Minimum purchase must be in only 12 digits";
//   // }
//   else {
//     return "";
//   }
// };

export const validateRoundPercent = (percent) => {
  const percentage = percent;
  if (percentage !== "") {
    if (percentage < 0 || percentage > 100) {
      return "Round percentage must be between 0 and 100";
    } else if (percentage < 0) {
      return "Round percentage must not be negative";
    } else if (percentage % 1 !== 0) {
      return "Round percentage must be a whole number";
    }
  } else {
    return "Please enter percentage";
  }
};

export const validateVestingPeriod = (percent) => {
  const maxVal = percent > 99;
  const minimum = percent < 1;
  if (!percent) {
    return "Please enter vesting period";
  } else if (minimum) {
    return "Vesting period cannot be less than 1";
  } else if (maxVal) {
    return "Vesting period cannot be in three digit";
  } else {
    return "";
  }
};
export const validateMonthlyRealese = (realese) => {
  const percentage = realese;
  if (percentage !== "") {
    if (percentage < 0 || percentage > 100) {
      return "Round percentage must be between 0 and 100";
    } else if (percentage < 0) {
      return "Round percentage must not be negative";
    } else if (percentage % 1 !== 0) {
      return "Round percentage must be a whole number";
    }
  } else {
    return "Please enter percentage";
  }
};
export const validateTokenQuantity = (quantity) => {
  if (!quantity) {
    return message.ENTER_TOKEN_QUANTITY;
  } else {
    return "";
  }
};
export const validatePrice = (price) => {
  const maximumValue = 99999999999;
  if (!price) {
    return message.ENTER_PRICE;
  } else if (price > maximumValue) {
    return message.MAXIMUM_PRICE;
  } else {
    return "";
  }
};
export const validateDatePicker = (date) => {
  if (!date) {
    return message.SELECT_DATE;
  } else {
    return "";
  }
};

export const validateConfirmPassword = (confirmPassword, password) => {
  // const num = /.*[0-9].*/.test(confirmPassword);
  // let firstChar = confirmPassword?.substring(0, 1);
  // let checkSpecial = /[@#%&$()^~{}]+/.test(confirmPassword);
  // const isValid = /[a-z]/.test(confirmPassword);
  // if (
  //   firstChar !== firstChar?.toUpperCase() ||
  //   !isNaN(firstChar) ||
  //   /[*@!#%&$()^`~{}]+/.test(firstChar) !== false
  // ) {
  //   return message.UPPERCASE;
  // } else if (!isValid) {
  //   return message.LOWER_CASE_CHARACTER;
  // } else if (!checkSpecial) {
  //   return message.SPECIAL_CHARACTER;
  // } else if (!num) {
  //   return message.AT_LEAST_DIGIT;
  // } else if (confirmPassword.length < 6) {
  //   return message.USER_NAME_LENGTH;
  // } else if (confirmPassword !== password) {
  //   return message.SAME_PASSWORD;
  // } else {
  //   return "";
  // }

  if (confirmPassword == "") {
    return message.ENTER_CONFIRM_PASSWORD;
  } else if (confirmPassword !== password) {
    return message.SAME_PASSWORD;
  } else {
    return "";
  }
};

export const validateConfirmNewPassword = (confirmPassword, password) => {
  if (confirmPassword == "") {
    return message.ENTER_NEW_CONFIRM_PASSWORD;
  } else if (confirmPassword !== password) {
    return message.MATCHED_NEW_CONFIRM_PASSWORD;
  } else {
    return "";
  }
};
