import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const WalletWithdraw = ({ withdrawRow, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const AdminToken = useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState("");
  const [setting, setSetting] = useState({});

  const getWalletList = async () => {
    await DataService(token)
      .get(API.Admin.GET_SETTING)
      .then((res) => {
        setSetting(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getWalletList();
  }, []);

  const handleWalletWithdraw = async () => {
    const data = {
      amount: amount,
      id: withdrawRow?.user_id,
    };
    console.log(data, 58);

    if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
      setAmountError("Please enter amount");
    } else {
      setAmountError("");
      await DataService(token)
        .post(API.Admin.GET_USER_WITHDRAW, data)
        .then((res) => {
          console.log(res?.data, 15);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <div>
      <Index.Box className="dashboard-content wallet-modal-set-height bg-white add-user-containt">
        <Index.Box className="user-head-title">
          <Index.Typography
            className="admin-page-title"
            component="h2"
            variant="h2"
          >
            Withdraw
          </Index.Typography>
        </Index.Box>

        <Index.Box className="add-user-data-main">
          <Index.Box className="admin-private-main">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 4",
                    md: "span 4",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className=" presale-input">
                    <Index.Box className="input-box input-box-admin input-box-admin2">
                      <Index.Box className="wallet-text-line-box wallet-text-line-box-1">
                        <Index.FormHelperText className="form-lable" for="user">
                          Form
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.Box className="wallet-text-line">
                            <Index.Typography>
                              {withdrawRow ? withdrawRow?.user_name : "-"}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 8",
                    md: "span 8",
                    lg: "span 8",
                  }}
                  className="grid-column"
                >
                  <Index.Box className=" presale-input">
                    <Index.Box className="input-box input-box-admin input-box-admin-wallet  input-box-admin2">
                      <Index.FormHelperText className="form-lable" for="user">
                        User Wallet Address
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.Box className="wallet-modal-box-set">
                          {/* <Index.Typography>{withdrawRow?withdrawRow?.wallet_address:"-"}</Index.Typography> */}
                          <Index.Typography>
                            {" "}
                            <Index.Tooltip
                              title={withdrawRow?.wallet_address}
                              arrow
                              placement="top"
                            >
                              <Index.Box className="email-ellip">
                                {withdrawRow?.wallet_address
                                  ? withdrawRow?.wallet_address
                                  : "-"}
                              </Index.Box>
                            </Index.Tooltip>
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 4",
                    md: "span 4",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className=" presale-input">
                    <Index.Box className="input-box input-box-admin input-box-admin2">
                      <Index.Box className="wallet-text-line-box wallet-text-line-box-1">
                        <Index.FormHelperText className="form-lable" for="user">
                          To
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.Box className="wallet-text-line">
                            <Index.Typography>FBC Admin</Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 8",
                    md: "span 8",
                    lg: "span 8",
                  }}
                  className="grid-column"
                >
                  <Index.Box className=" presale-input">
                    <Index.Box className="input-box input-box-admin input-box-admin-wallet  input-box-admin2">
                      <Index.FormHelperText className="form-lable" for="user">
                        Wallet Address
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.Box className="wallet-modal-box-set">
                          <Index.Typography>
                            <Index.Tooltip
                              title={setting?.wallet_address}
                              arrow
                              placement="top"
                            >
                              <Index.Box className="email-ellip">
                                {setting ? setting?.wallet_address : "-"}
                              </Index.Box>
                            </Index.Tooltip>
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className=" presale-input">
                    <Index.Box className="input-box input-box-admin input-box-admin-wallet  input-box-admin2">
                      <Index.FormHelperText className="form-lable" for="user">
                        Network
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.Box className="wallet-modal-box-set">
                          <Index.Typography>BNB Smart Chain</Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className=" presale-input">
                    <Index.Box className="input-box input-box-admin input-box-admin-wallet  input-box-admin2">
                      <Index.FormHelperText className="form-lable" for="user">
                        Coin
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.Box className="wallet-modal-box-set">
                          <Index.Typography>Coin Pair</Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className=" presale-input">
                    <Index.Box className="input-box input-box-admin input-box-admin-wallet  input-box-admin2">
                      <Index.FormHelperText className="form-lable" for="user">
                        Available Balance
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.Box className="wallet-modal-box-set">
                          <Index.Typography>
                            {withdrawRow ? withdrawRow?.withdraw : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-text-field-main presale-input">
                    <Index.Box className="input-box input-box-admin input-box-admin2">
                      <Index.Box className="form-group">
                        <Index.TextField
                          className="admin-textfield"
                          id="outlined-basic"
                          type="number"
                          name="amount"
                          placeholder="Please Enter Round No"
                          value={amount}
                          onChange={(e) => {
                            setAmount(e.target.value);
                            setAmountError("");
                          }}
                        />
                      </Index.Box>
                      <p className="error-text">{amountError}</p>{" "}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="private-admin-btn-main primary-btn-main-comman modal-wallet">
              <Index.PrimaryButton
                className="sale-btn add-user-btn admin-private-btn btn-dark-comman-main box-shadow-none"
                type="button"
                variant="contained"
                btnLabel="Cancel"
                onClick={() => {
                  onClose();
                }}
              />
              <Index.PrimaryButton
                className="sale-btn add-user-btn admin-private-btn wallet-modal-btn-set btn-dark-comman-main box-shadow-none"
                type="button"
                variant="contained"
                btnLabel="Withdraw"
                onClick={() => {
                  handleWalletWithdraw();
                }}
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default WalletWithdraw;
