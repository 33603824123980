import React from "react";
import _ from "lodash";
import Index from "../component/Index";

function Separator(props) {
  return (
    <Index.Box
      style={{
        position: "absolute",
        height: "100%",
        transform: `rotate(${props.turns}turn)`
      }}
    >
      <Index.Box style={props.style} />
    </Index.Box>
  );
}

function RadialSeparators(props) {
  const turns = 1 / props.count;
  return _.range(props.count).map(index => (
    <Separator turns={index * turns} style={props.style} />
  ));
}

export default RadialSeparators;
