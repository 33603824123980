
import React from "react";
import Index from "../component/Index";
import CircularProgress from "@mui/material/CircularProgress";

export default function FbcLoading() {
  return (
    <div>
      <Index.Box className="loader-bg-main">
        <Index.Box className="f-direction">
          <Index.Box className="login-logo-details logo-anim position-relative">
            <Index.Box className="coin-image">
            <img
            src={Index.Png.FBC_NEW_LOGO}
            className="user-sidebar-loading-icon  user-sidebar-icon"
          />
           {/* <CircularProgress /> */}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
