import React, { lazy } from "react";
import { BrowserRouter, Route, Routes as Routess } from "react-router-dom";
import { createBrowserHistory } from "history";
import Loadable from "../common/Loadable";
import { Navigate } from "react-router-dom";
import ProtectedAdminRoutes from "./ProtectedAdminRoutes";
import ExchangeDepositList from "../container/admin/pages/exchangeDeposit/ExchangeDepositList";
import AddIncome from "../container/user/pages/my_income/AddIncome";
import UserDetail from "../container/admin/pages/userDetails/UserDetail";
import Wallet from "../container/admin/pages/wallet/Wallet";
import WalletWithdraw from "../container/admin/pages/wallet/WalletWithdraw";
import WalletDeposit from "../container/admin/pages/wallet/WalletDeposit";
import Social from "../container/user/pages/social/Social";
import SocialMediaPostRequest from "../container/admin/pages/socialMedia/SocialMediaPostRequestList";
import ViewPostRequest from "../container/admin/pages/socialMedia/ViewPostRequest";
import ActiveUserList from "../container/admin/pages/activeUserList/ActiveUserList";
import NetworkTransferList from "../container/admin/pages/networkTransferList/NetworkTransferList";
import ExchangeTransferList from "../container/admin/pages/exchangeTransferList/ExchangeTransferList";
// lazy loading
// import AddUser from "../container/admin/pages/user/AddUser";
// import Dashboard from "../container/admin/pages/dashboard/Dashboard";
// import Login from "../container/admin/auth/Login";
// import Referral from "../container/user/pages/referral/Referral";
// import EditProfile from "../container/admin/pages/editprofile/EditProfile";
// import TradeSummary from "../container/admin/pages/tradeSummary/TradeSummary";
// import UserList from "../container/admin/pages/user/UserList";
// import UserLogin from "../container/user/auth/login/Login";
// import Home from "../container/user/pages/home/Home";
// import PresaleRound from "../container/admin/pages/privateSale/PresaleRound";
// import PrivateAdminSalefrom from "../container/admin/pages/privateSale/PrivateAdminSale";
// import Waitlist from "../container/admin/pages/waitlist/Waitlist";
// import UserDashboard from "../container/user/pages/userDashboard/UserDashboard";
// import DashboardContent from "../container/user/pages/dashboardcontent/DashboardContent";
// import AdminSetting from "../container/admin/pages/setting/AdminSetting";
// import Setting from "../container/user/pages/setting/Setting";
// import PrivateRoutes from "./ProtectedUserRoutes";
// import Referral from "../container/user/pages/referral/Referral";
// import Chart from "../container/user/pages/treeStructure/Chart";
// import AdminDashboard from "../container/admin/pages/dashboard/AdminDashboard";
// import TransactionList from "../container/admin/pages/transactions/TransactionList";
// import PrivacyPolicy from "../container/admin/pages/cms/PrivacyPolicy";
// import TermsAndCondition from "../container/admin/pages/cms/TermsAndCondition";
// import AboutUs from "../container/admin/pages/cms/Aboutus";
// import ForgotPassword from "../container/admin/auth/ForgotPassword";
// import OtpVerify from "../container/admin/auth/OtpVerify";
// import UserOtpVerify from "../container/user/auth/otp-verify/OtpVerify";
// import UserForgotPassword from "../container/user/auth/forgot-password/ForgotPassowrd";
// import UserResetPassword from "../container/user/auth/reset-password/ResetPassword";
// import ResetPassword from "../container/admin/auth/ResetPassword";
// import MyTransaction from "../container/user/pages/mytransaction/MyTransaction";
// import UserDetails from "../container/admin/pages/user/UserDetails";
// import UserEditProfile from "../container/user/pages/userProfile/UserEditProfile";
// import GeneralSetting from "../container/user/pages/userProfile/GeneralSetting";
// import WaitlistTab from "../container/admin/pages/waitlist/waitlistTab/WaitlistTab";
// import PresaleTab from "../container/admin/pages/privateSale/presaleTab/PresaleTab";
// import Userkyc from "../container/user/pages/kyc/Kyc";
// import KycList from "../container/admin/pages/kyc/KycList";
// import AdminKycview from "../container/admin/pages/kyc/KycView";
// import ReferralTab from "../container/user/pages/referral/ReferralTab";
// import SupportUser from "../container/user/pages/support/SupportUser";
// import MainReferral from "../container/admin/pages/mainReferral/MainReferral";
// import ReferralUserList from "../container/user/pages/referral/ReferralUserList";
// import KycResponse from "../container/user/pages/kyc/KycResponse";
// import StackingTab from "../container/admin/pages/stacking/stackingTab/StackingTab";
// import WithdrawList from "../container/admin/pages/withdraw/WithdrawList";
// import TokenCategoryList from "../container/admin/pages/tokenCategory/TokenCategoryList";
// import TokenCategoryDetails from "../container/admin/pages/tokenCategory/TokenCategoryDetails";
// import Team from "../container/user/pages/team/Team";
// import Kyc from "../container/user/pages/userKyc/Kyc";
// import MyIncome from "../container/user/pages/my_income/MyIncome";
// import UserWithdrawList from "../container/user/pages/withdraw/UserWithdrawList";
// import UserSwapList from "../container/user/pages/swap/UserSwapList";
// import StakList from "../container/user/pages/staklist/StakList";

const StakList = Loadable(
  lazy(() => import("../container/user/pages/staklist/StakList"))
);
const UserSwapList = Loadable(
  lazy(() => import("../container/user/pages/swap/UserSwapList"))
);
const UserWithdrawList = Loadable(
  lazy(() => import("../container/user/pages/withdraw/UserWithdrawList"))
);
const MyIncome = Loadable(
  lazy(() => import("../container/user/pages/my_income/MyIncome"))
);
const Kyc = Loadable(lazy(() => import("../container/user/pages/userKyc/Kyc")));
const Team = Loadable(lazy(() => import("../container/user/pages/team/Team")));
const TokenCategoryDetails = Loadable(
  lazy(() =>
    import("../container/admin/pages/tokenCategory/TokenCategoryDetails")
  )
);
const TokenCategoryList = Loadable(
  lazy(() => import("../container/admin/pages/tokenCategory/TokenCategoryList"))
);
const WithdrawList = Loadable(
  lazy(() => import("../container/admin/pages/withdraw/WithdrawList"))
);
const StackingTab = Loadable(
  lazy(() =>
    import("../container/admin/pages/stacking/stackingTab/StackingTab")
  )
);
const KycResponse = Loadable(
  lazy(() => import("../container/user/pages/kyc/KycResponse"))
);
const ReferralUserList = Loadable(
  lazy(() => import("../container/user/pages/referral/ReferralUserList"))
);
const MainReferral = Loadable(
  lazy(() => import("../container/admin/pages/mainReferral/MainReferral"))
);
const SupportUser = Loadable(
  lazy(() => import("../container/user/pages/support/SupportUser"))
);
const ReferralTab = Loadable(
  lazy(() => import("../container/user/pages/referral/ReferralTab"))
);
const AdminKycview = Loadable(
  lazy(() => import("../container/admin/pages/kyc/KycView"))
);
const KycList = Loadable(
  lazy(() => import("../container/admin/pages/kyc/KycList"))
);
const Userkyc = Loadable(lazy(() => import("../container/user/pages/kyc/Kyc")));
const PresaleTab = Loadable(
  lazy(() =>
    import("../container/admin/pages/privateSale/presaleTab/PresaleTab")
  )
);
const WaitlistTab = Loadable(
  lazy(() =>
    import("../container/admin/pages/waitlist/waitlistTab/WaitlistTab")
  )
);
const GeneralSetting = Loadable(
  lazy(() => import("../container/user/pages/userProfile/GeneralSetting"))
);
const UserEditProfile = Loadable(
  lazy(() => import("../container/user/pages/userProfile/UserEditProfile"))
);
const UserDetails = Loadable(
  lazy(() => import("../container/admin/pages/user/UserDetails"))
);
const MyTransaction = Loadable(
  lazy(() => import("../container/user/pages/mytransaction/MyTransaction"))
);
const ResetPassword = Loadable(
  lazy(() => import("../container/admin/auth/ResetPassword"))
);
const UserResetPassword = Loadable(
  lazy(() => import("../container/user/auth/reset-password/ResetPassword"))
);
const UserForgotPassword = Loadable(
  lazy(() => import("../container/user/auth/forgot-password/ForgotPassowrd"))
);
const UserOtpVerify = Loadable(
  lazy(() => import("../container/user/auth/otp-verify/OtpVerify"))
);
const OtpVerify = Loadable(
  lazy(() => import("../container/admin/auth/OtpVerify"))
);
const ForgotPassword = Loadable(
  lazy(() => import("../container/admin/auth/ForgotPassword"))
);
const AboutUs = Loadable(
  lazy(() => import("../container/admin/pages/cms/Aboutus"))
);
const TermsAndCondition = Loadable(
  lazy(() => import("../container/admin/pages/cms/TermsAndCondition"))
);
const PrivacyPolicy = Loadable(
  lazy(() => import("../container/admin/pages/cms/PrivacyPolicy"))
);
const TransactionList = Loadable(
  lazy(() => import("../container/admin/pages/transactions/TransactionList"))
);
const Chart = Loadable(
  lazy(() => import("../container/user/pages/treeStructure/Chart"))
);
const Referral = Loadable(
  lazy(() => import("../container/user/pages/referral/Referral"))
);
const PrivateRoutes = Loadable(lazy(() => import("./ProtectedUserRoutes")));

const Setting = Loadable(
  lazy(() => import("../container/user/pages/setting/Setting"))
);
const AdminSetting = Loadable(
  lazy(() => import("../container/admin/pages/setting/AdminSetting"))
);
const DashboardContent = Loadable(
  lazy(() =>
    import("../container/user/pages/dashboardcontent/DashboardContent")
  )
);
const UserDashboard = Loadable(
  lazy(() => import("../container/user/pages/userDashboard/UserDashboard"))
);
const Waitlist = Loadable(
  lazy(() => import("../container/admin/pages/waitlist/Waitlist"))
);
const PrivateAdminSalefrom = Loadable(
  lazy(() => import("../container/admin/pages/privateSale/PrivateAdminSale"))
);
const PresaleRound = Loadable(
  lazy(() => import("../container/admin/pages/privateSale/PresaleRound"))
);
const Home = Loadable(lazy(() => import("../container/user/pages/home/Home")));
const UserLogin = Loadable(
  lazy(() => import("../container/user/auth/login/Login"))
);
const UserList = Loadable(
  lazy(() => import("../container/admin/pages/user/UserList"))
);
const EditProfile = Loadable(
  lazy(() => import("../container/admin/pages/editprofile/EditProfile"))
);
const TradeSummary = Loadable(
  lazy(() => import("../container/admin/pages/tradeSummary/TradeSummary"))
);
const AdminDashboard = Loadable(
  lazy(() => import("../container/admin/pages/dashboard/AdminDashboard"))
);
const AddUser = Loadable(
  lazy(() => import("../container/admin/pages/user/AddUser"))
);
const Dashboard = Loadable(
  lazy(() => import("../container/admin/pages/dashboard/Dashboard"))
);
const Login = Loadable(lazy(() => import("../container/admin/auth/Login")));
const history = createBrowserHistory();
// const Routers = () => {
//   return (
export default function Routes() {
  return (
    <BrowserRouter history={history}>
      <Routess>
        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin/forgot-password" element={<ForgotPassword />} />
        <Route path="/admin/reset-password/:id" element={<ResetPassword />} />
        <Route path="/admin/otp" element={<OtpVerify />} />
        <Route path="/admin/response" element={<KycResponse />} />
        <Route element={<ProtectedAdminRoutes />}>
          <Route path="admin" element={<Dashboard />}>
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="edit-profile" element={<EditProfile />} />
            <Route path="user-list" element={<UserList />} />
            <Route path="active-user-list" element={<ActiveUserList />} />
            <Route path="network-transfer-list" element={<NetworkTransferList />} />
            <Route path="exchange-transfer-list" element={<ExchangeTransferList />} />
            <Route path="user-details/:userId" element={<UserDetails />} />
            <Route path="add-user" element={<AddUser />} />
            <Route path="edit-user/:editId" element={<AddUser />} />
            <Route path="presale-round" element={<PresaleRound />} />
            <Route path="privateadminsale" element={<PrivateAdminSalefrom />} />
            <Route path="presale-details/:presaleId" element={<PresaleTab />} />
            <Route path="transaction" element={<TransactionList />} />
            <Route path="exchange-deposit" element={<ExchangeDepositList />} />
            <Route path="user_detail/:id" element={<UserDetail />} />
            <Route path="social-media-post-request" element={<SocialMediaPostRequest />} />
            <Route path="social-media-post-request/:id" element={<ViewPostRequest />} />

            <Route path="withdraw-list" element={<WithdrawList />} />
            <Route path="kyc-list" element={<KycList />} />
            <Route path="wallet" element={<Wallet />} />
            <Route path="wallet-deposit" element={<WalletDeposit />} />
            <Route path="wallet-withdraw" element={<WalletWithdraw />} />
            <Route path="Kycview/:id" element={<AdminKycview />} />

            <Route path="stack" element={<StackingTab />} />

            <Route path="waitlist-details/:userId" element={<WaitlistTab />} />
            <Route path="main-referral-list" element={<MainReferral />} />
            <Route path="token-details" element={<TokenCategoryList />} />
            <Route
              path="token-details/:categoryId"
              element={<TokenCategoryDetails />}
            />
            <Route
              path="privateadminsale/:editId"
              element={<PrivateAdminSalefrom />}
            />
            <Route path="waitlist" element={<Waitlist />} />
            <Route path="setting" element={<AdminSetting />} />
            <Route path="trade-summery" element={<TradeSummary />} />

            <Route
              path="privateadminsale/:editId"
              element={<PrivateAdminSalefrom />}
            />

            {/* <Route path="cms">
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-and-condition" element={<TermsAndCondition />} />
        </Route> */}
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/login" replace={true} />} />
        <Route path="/login" element={<UserLogin />} />
        <Route path="/forgot" element={<UserForgotPassword />} />
        <Route
          path="/user/reset-password/:id"
          element={<UserResetPassword />}
        />
        <Route path="/otp" element={<UserOtpVerify />} />
        <Route path="/register" element={<Home />} />

        <Route element={<PrivateRoutes />}>
          <Route path="/u" element={<UserDashboard />}>
            <Route path="dash" element={<DashboardContent />} />
            <Route path="referral" element={<Referral />} />
            <Route path="team" element={<Team />} />
            {/* <Route path="all_income" element={<AllInCome />} /> */}
            <Route path="stak-list" element={<StakList />} />
            <Route path="my_income" element={<MyIncome />} />
            <Route path="add-income" element={<AddIncome />} />
            <Route path="tree" element={<Chart />} />
            <Route path="transaction" element={<MyTransaction />} />
            <Route path="edit" element={<UserEditProfile />} />
            <Route path="account-setting" element={<GeneralSetting />} />
            <Route path="kyc" element={<Userkyc />} />
            <Route path="user-kyc" element={<Kyc />} />
            <Route path="user-withdraw-List" element={<UserWithdrawList />} />
            <Route path="user-swap-List" element={<UserSwapList />} />
            <Route path="referralInfo" element={<ReferralTab />} />
            <Route path="support" element={<SupportUser />} />
          <Route path="social" element={<Social />} />
            <Route path="refUsers" element={<ReferralUserList />} />
          </Route>
        </Route>
      </Routess>
    </BrowserRouter>
  );
}

