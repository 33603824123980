import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useSelector } from "react-redux";
import FbcLoading from "../../../../common/FbcLoading";
import { useNavigate } from "react-router-dom";

export default function SocialMediaPostRequestList() {
  const AdminToken = useSelector((store) => store.admin.admintoken);
  const navigate = useNavigate();
  let token = window.atob(AdminToken);
  const [status, setStatus] = useState(1);
  const [postList, setPostList] = useState([]);
  console.log(postList, 15);
  const [filterData, setFilterData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleView = (row) => {
    navigate(`/admin/social-media-post-request/${row?._id}`, {
      state: { row },
    });
  };
  // Get Kyc List
  const getSocialRequestPostList = async () => {
    setLoader(true);
    try {
      const res = await DataService(token).post(
        API.Admin.GET_SOCIAL_MEDIA_POST_LIST
      );
      const data = res?.data?.data;
      setPostList(data);
      setLoader(false);
    } catch (err) {
      console.log(err, ":errs");
    }
  };
  useEffect(() => {
    getSocialRequestPostList();
  }, []);

  if (loader) {
    return <FbcLoading />;
  } else {
    return (
      <>
        <Index.Box className="dashboard-content add-user-containt bg-white">
          <Index.Box className="user-head-title">
            <Index.Typography
              className="admin-page-title"
              component="h2"
              variant="h2"
            >
              Social Media Post Request List
            </Index.Typography>
          </Index.Box>

          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="">
                    <Index.Box className="page-table-main kyc-table-list kyc-table-main table-design-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Date
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Name
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Status
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {postList.length > 0 ? (
                              postList
                                ?.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                ?.map((row) => {
                                  return (
                                    <Index.TableRow
                                      // key={row._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        {row?.createdAt
                                          ? Index.moment(row?.createdAt)
                                              .utcOffset("+05:30")
                                              .format("DD-MM-YYYY HH:mm:ss")
                                          : "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        <Index.Tooltip
                                          // title={row?.user_id?.user_name}
                                          arrow
                                          placement="top"
                                        >
                                          {row?.user_id?._id
                                            ? row?.user_id?.user_name
                                            : "-"}
                                        </Index.Tooltip>
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="right"
                                      >
                                        {/* {row?.status ? row?.status : "-"} */}
                                        {row?.status
                                          ? row?.status === "approved"
                                            ? "Approved"
                                            : row?.status === "pending"
                                            ? "Pending"
                                            : row?.status === "rejected"
                                            ? "Rejected"
                                            : "-"
                                          : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td email-ellip"
                                        align="right"
                                      >
                                        <Index.IconButton
                                          onClick={() => {
                                            handleView(row);
                                          }}
                                        >
                                          <Index.Tooltip title="view">
                                            <Index.Visibility className="email-icon" />
                                          </Index.Tooltip>
                                        </Index.IconButton>
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  );
                                })
                            ) : (
                              <Index.TableRow>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  colSpan={7}
                                >
                                  No Record found
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>{" "}
                  <Index.Box className="pagination-main">
                    <Index.TablePagination
                      component="div"
                      page={page}
                      count={postList.length}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[15, 25, 50,100,200]}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelDisplayedRows={(page) =>
                        `Records ${page.from} to ${
                          page.to === -1 ? page.count : page.to
                        } of ${page.count}`
                      }
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </>
    );
  }
}
