import React from "react";
import Index from "../component/Index";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import RadialSeparators from "../common/RadialSeparators";

export default function TokenPreSaleProgress({ progress }) {
  function TokenProgress(props) {
    return (
      <Index.Box className="prog-wrapper">
        <Index.Box style={{ width: "200px" }}>{props.children}</Index.Box>
      </Index.Box>
    );
  }

  return (
    <>
      <TokenProgress label="Progressbar with separators" className="token-prog">
        <CircularProgressbarWithChildren
          value={progress ? progress?.toFixed(0) : 0}
          text={progress ? `${progress?.toFixed(0)}%` : 0}
          strokeWidth={10}
          styles={buildStyles({
            strokeLinecap: "butt",
          })}
        >
          <RadialSeparators
            count={12}
            style={{
              background: "#fff",
              width: "2px",
              // This needs to be equal to props.strokeWidth
              height: `${10}%`,
            }}
          />
        </CircularProgressbarWithChildren>
      </TokenProgress>
    </>
  );
}
