import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import { useSelector } from "react-redux";

// model
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import WalletWithdraw from "./WalletWithdraw";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import FbcLoading from "../../../../common/FbcLoading";
import WalletDeposit from "./WalletDeposit";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 755,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  // max-width: 752px ;
  //   width: 100% ;
};

export default function Wallet() {
  const AdminToken = useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);
  const [loader, setLoader] = useState(false);
  const [filterData, setFilterData] = useState([]);
  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [count, setCount] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = (row) => {
    setWithdrawRow(row);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [withdrawRow, setWithdrawRow] = useState({});

  const [depositModalOpen, setDepositModalOpen] = React.useState(false);
  const handleDepositModalOpen = (row) => {
    setDepositModalOpen(true);
  };
  const handleDepositModalClose = () => setDepositModalOpen(false);

  const getWalletList = async () => {
    setLoader(true);
    const data = {
      skip: page,
      limit: rowsPerPage,
    };
    await DataService(token)
      .post(API.Admin.GET_USER_DATA, data)
      .then((res) => {
        setLoader(false);
        setFilterData(res?.data?.data?.userData);
        setCount(res?.data?.data?.countOfUser)
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  useEffect(() => {
    getWalletList();
  }, [page,rowsPerPage]);

  if (loader) {
    return <FbcLoading />;
  } else {
    return (
      <div>
        {/* model */}
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <WalletWithdraw withdrawRow={withdrawRow} onClose={handleClose} />
            </Box>
          </Modal>
          <Modal
            open={depositModalOpen}
            onClose={handleDepositModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <WalletDeposit
                withdrawRow={withdrawRow}
                onClose={handleDepositModalClose}
              />
            </Box>
          </Modal>
        </div>
        <Index.Box className="dashboard-content bg-white add-user-container transaction-wrap">
          <Index.Box className="user-head-title">
            <Index.Typography
              className="admin-page-title user-list-page-title res-admin-page-title-set"
              component="h2"
              variant="h2"
            >
              Wallet
            </Index.Typography>

            {/* <Index.Box className="transaction-right">
              <Index.Box className="preasale-search">
                <Index.Box className="user-search-main cus-search">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        value={searchVal}
                        placeholder="Search..."
                        onChange={(e) => handleSearch(e)}
                      />

                      <img
                        src={Index.Svg.greysearch}
                        className="search-grey-img"
                        alt="search grey img"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box> */}
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="">
                    <Index.Box className="page-table-main table-design-main admin-user-wallet-list">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                User Name
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                User Email
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Wallet Address
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Deposited Balance
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Un Used USDT
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                FBC Coin
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Suggested Withdraw
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>

                          <Index.TableBody className="table-body">
                            {filterData?.length > 0 ? (
                              filterData?.map((row, index) => {
                                let network = Index.selectNetwork(
                                  row?.network_type
                                );

                                return (
                                  <Index.TableRow
                                    key={index}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.user_name ? row?.user_name : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Tooltip
                                        title={row?.email}
                                        arrow
                                        placement="top"
                                      >
                                        <Index.Box className="email-ellip">
                                          {row?.email ? row?.email : "-"}
                                        </Index.Box>
                                      </Index.Tooltip>
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="right"
                                    >
                                      <Index.Tooltip
                                        title={row?.wallet_address}
                                        arrow
                                        placement="top"
                                      >
                                        <Index.Box className="email-ellip">
                                          {row?.wallet_address
                                            ? row?.wallet_address
                                            : "-"}
                                        </Index.Box>
                                      </Index.Tooltip>
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.deposit
                                        ? row?.deposit?.toFixed(3)
                                        : 0}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.remain
                                        ? row?.remain?.toFixed(3)
                                        : 0}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.fbcCoin
                                        ? row?.fbcCoin?.toFixed(3)
                                        : 0}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.withdraw ? row?.withdraw : 0}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Box sx={{ display: "flex" }}>
                                        <Index.Button
                                          // onClick={handleOpen}
                                          onClick={() => {
                                            handleOpen(row);
                                          }}
                                          sx={{ marginRight: 2 }}
                                          variant="contained"
                                        >
                                          Withdraw
                                        </Index.Button>
                                        <Index.Button
                                          // onClick={() => {
                                          //   handleDepositModalOpen(row);
                                          // }}
                                          onClick={() => {
                                            handleDepositModalOpen();
                                          }}
                                          variant="contained"
                                        >
                                          Deposit
                                        </Index.Button>
                                      </Index.Box>
                                    </Index.TableCell>
                                  </Index.TableRow>
                                );
                              })
                            ) : (
                              <Index.TableRow>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  colSpan={7}
                                >
                                  No Record found
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>{" "}
                  <Index.Box className="pagination-main">
                    <Index.TablePagination
                      component="div"
                      page={page}
                      // count={filterData?.length}
                      count={count}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[15, 25, 50]}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelDisplayedRows={(page) =>
                        `Records ${page.from} to ${
                          page.to === -1 ? page.count : page.to
                        } of ${page.count}`
                      }
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </div>
    );
  }
}
