function ReplaceCharsWithAsterisks(str) {
  const firstTwoChars = str.substring(0, 2);
  const lastChar = str.slice(-1);
  const middleChars = "*".repeat(str.length - 3);
  return firstTwoChars + middleChars + lastChar;
}

function sortInAscending(field, subField) {
  return function (a, b) {
    let value1 = a[field][subField]?.toLowerCase();
    let value2 = b[field][subField]?.toLowerCase();

    if (value1 < value2) {
      return -1;
    }
    if (value1 > value2) {
      return 1;
    }
    return 0;
  };
}

function sortInDescending(field, subField) {
  return function (a, b) {
    let value1 = a[field][subField]?.toLowerCase();
    let value2 = b[field][subField]?.toLowerCase();

    if (value1 < value2) {
      return 1;
    }
    if (value1 > value2) {
      return -1;
    }
    return 0;
  };
}

function sortFieldInAscending(field) {
  return function (a, b) {
    let value1 = a[field]?.toLowerCase();
    let value2 = b[field]?.toLowerCase();

    if (value1 < value2) {
      return -1;
    }
    if (value1 > value2) {
      return 1;
    }
    return 0;
  };
}

function sortFieldInDescending(field) {
  return function (a, b) {
    let value1 = a[field]?.toLowerCase();
    let value2 = b[field]?.toLowerCase();

    if (value1 < value2) {
      return 1;
    }
    if (value1 > value2) {
      return -1;
    }
    return 0;
  };
}

const IdentifyAddressProof = (id) => {
  let DocumentName = "";

  switch (id) {
    case "10":
      DocumentName = "Aadhar Card";
      break;
    case "20":
      DocumentName = "Passport";
      break;
    case "30":
      DocumentName = "VoterId";
      break;
    case "40":
      DocumentName = "Driving licence";
      break;
    case "50":
      DocumentName = "Other government approved Id";
      break;
    default:
      return false;
  }

  return DocumentName;
};

const validateInpField = (field, message) => {
  let errorMessage = "";
  if (field == "") {
    errorMessage = message;
  } else {
    errorMessage = "";
  }
  return errorMessage;
};

export {
  ReplaceCharsWithAsterisks,
  sortInAscending,
  sortInDescending,
  sortFieldInAscending,
  sortFieldInDescending,
  IdentifyAddressProof,
  validateInpField,
};
