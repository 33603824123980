import React, { Suspense } from "react";
import FbcLoading from "./FbcLoading";
import Index from "../component/Index";

const Loadable = (Component) => (props) =>
  (
    <>
      {/* <Suspense fallback={<FbcLoading />}> */}
      <Suspense
        fallback={
          <Index.Box className="loader-bg-main-lazy-loader">
            <img
              src={Index.Png.FBC_NEW_LOGO}
              className="user-sidebar-loading-icon  user-sidebar-icon"
            />
          </Index.Box>
        }
      >
        <Component {...props} />
      </Suspense>
    </>
  );

export default Loadable;
