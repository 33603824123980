import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { TabContext, TabList } from "@mui/lab";
import { Tab } from "@mui/material";
import { useParams } from "react-router-dom";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useSelector } from "react-redux";

const UserDetail = () => {
  const AdminToken = useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);
  const { id } = useParams();
  const [value, setValue] = useState("tab1");
  const [radioValue, setRadioValue] = useState("stakingList");
  const [exchangeRadioValue, setExchangeRadioValue] =
    useState("depositExchange");
  const [users, setUsers] = useState([]);
  const [staking, setStaking] = useState([]);
  const [networkTransfer, setNetworkTransfer] = useState([]);
  const [networkIncome, setNetworkIncome] = useState([]);
  const [networkTeam, setNetworkTeam] = useState([]);
  const [exchangeTransfer, setExchangeTransfer] = useState([]);
  const [exchangeDeposit, setExchangeDeposit] = useState([]);
  const [exchangeWithdraw, setExchangeWithdraw] = useState([]);
  const handleRadioChange = (newValue, e) => {
    setRadioValue(e);
    setPage(0);
  };
  const handleExchangeRadioChange = (newValue, e) => {
    setExchangeRadioValue(e);
    setPage(0);
  };
  const onHandleNetworkTab = () => {
    setValue("tab1");
    setRadioValue("stakingList");
    setExchangeRadioValue("");
  };
  const onHandleExchangeTab = () => {
    setValue("tab2");
    setExchangeRadioValue("depositExchange");
    setRadioValue("");
  };

  // Start Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [count, setCount] = useState(0);

  // deposit pagination
  const [exPage, setExPage] = useState(0);
  const [exRowsPerPage, setExRowsPerPage] = useState(15);
  const [exCount, setExCount] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const exHandleChangePage = (event, newPage) => {
    setExPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const exHandleChangeRowsPerPage = (event) => {
    setExRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // End Pagination

  const getUserDetails = async () => {
    const data = new URLSearchParams();
    data.append("id", id);
    await DataService(token)
      .post(API.Admin.GET_USER_DETAIL, data)
      .then((res) => {
        setUsers(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStakeList = async () => {
    const data = new URLSearchParams();
    data.append("pageSize", rowsPerPage);
    data.append("pageNumber", page + 1);
    data.append("id", id);
    await DataService(token)
      .post(API.Admin.GET_STAKING_LIST, data)
      .then((res) => {
        setCount(0);
        setCount(res?.data?.data?.recordCount);
        setStaking(res?.data?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getNetworkTransfer = async () => {
    const data = new URLSearchParams();
    data.append("pageSize", rowsPerPage);
    data.append("pageNumber", page + 1);
    data.append("id", id);
    await DataService(token)
      .post(API.Admin.GET_USER_NETWORK_TRANSFER, data)
      .then((res) => {
        setCount(0);
        setCount(res?.data?.data?.recordCount);
        setNetworkTransfer(res?.data?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getNetworkIncome = async () => {
    const data = new URLSearchParams();
    data.append("pageSize", rowsPerPage);
    data.append("pageNumber", page + 1);
    data.append("id", id);
    await DataService(token)
      .post(API.Admin.GET_USER_INCOME, data)
      .then((res) => {
        setCount(0);
        setCount(res?.data?.data?.recordCount);
        setNetworkIncome(res?.data?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getNetworkTeam = async () => {
    const data = new URLSearchParams();
    data.append("id", id);
    await DataService(token)
      .post(API.Admin.GET_USER_TEAM, data)
      .then((res) => {
        setNetworkTeam(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getExchangeTransfer = async () => {
    const data = new URLSearchParams();
    data.append("pageSize", rowsPerPage);
    data.append("pageNumber", page + 1);
    data.append("id", id);
    await DataService(token)
      .post(API.Admin.GET_USER_EXCHANGE_TRANSFER, data)
      .then((res) => {
        setCount(0);
        setCount(res?.data?.data?.recordCount);
        setExchangeTransfer(res?.data?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getExchangeDeposit = async () => {
    const data = new URLSearchParams();
    data.append("pageSize", exRowsPerPage);
    data.append("pageNumber", exPage + 1);
    data.append("id", id);
    await DataService(token)
      .post(API.Admin.GET_USER_EXCHANGE_DEPOSIT, data)
      .then((res) => {
        setExCount(res?.data?.data?.recordCount);
        setExchangeDeposit(res?.data?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getExchangeWithdraw = async () => {
    const data = new URLSearchParams();
    data.append("pageSize", rowsPerPage);
    data.append("pageNumber", page + 1);
    data.append("id", id);
    await DataService(token)
      .post(API.Admin.GET_USER_EXCHANGE_WITHDRAW, data)
      .then((res) => {
        setCount(0);
        setCount(res?.data?.data?.recordCount);
        setExchangeWithdraw(res?.data?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (value == "tab1") {
      if (radioValue === "stakingList") {
        getStakeList();
      }
      if (radioValue === "walletStakingList") {
        getNetworkTransfer();
      }
      if (radioValue === "income") {
        getNetworkIncome();
      }
      if (radioValue === "team") {
        getNetworkTeam();
      }
    } else {
      if (exchangeRadioValue === "transferList") {
        getExchangeTransfer();
      }
      if (exchangeRadioValue === "depositExchange") {
        getExchangeDeposit();
      }
      if (exchangeRadioValue === "withdrawList") {
        getExchangeWithdraw();
      }
    }
  }, [
    radioValue,
    rowsPerPage,
    page,
    exchangeRadioValue,
    exPage,
    exRowsPerPage,
  ]);

  useEffect(() => {
    getUserDetails();
  }, []);

  const formatData = (data) => {
    const prefix = data?.substring(0, 2);
    const lastTwoCharacters = data?.substring(data?.length - 2);
    const maskedData = `${prefix}......${lastTwoCharacters}`;
    return maskedData;
  };

  return (
    <>
      <Index.Box className="dashboard-content referral-list-tree-btn">
        <Index.Typography
          className="admin-page-title res-referral-list-tree-btn"
          component="h2"
          variant="h2"
        >
          User Details
        </Index.Typography>
      </Index.Box>
      <Index.Box className="admin-dashboad-row res-admin-dashboad-row">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            className="display-row"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="admin-dashboad-row res-admin-dashboad-row mb-0">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 4",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column income-card"
                    >
                      <Index.Box className="admin-dashboard-box token-card">
                        <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                          <Index.Box className="admin-dash-left">
                            <Index.Typography
                              className="admin-dash-price user-dash-price user-details-price"
                              variant="p"
                              component="p"
                            >
                              Stacked Coin
                            </Index.Typography>
                            <Index.Typography
                              className="admin-dash-text user-dash-text user-details-text"
                              variant="h1"
                              component="h1"
                            >
                              {users ? users?.stakeCoin?.toFixed(2) : 0}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 4",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column income-card"
                    >
                      <Index.Box className="admin-dashboard-box token-card">
                        <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                          <Index.Box className="admin-dash-left">
                            <Index.Typography
                              className="admin-dash-price user-dash-price user-details-price"
                              variant="h1"
                              component="h1"
                            >
                              Un Stake Coin
                            </Index.Typography>
                            <Index.Typography
                              className="admin-dash-text user-dash-text user-details-text"
                              variant="p"
                              component="p"
                            >
                              {users ? users?.totalUnstakeCoin?.toFixed(2) : 0}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 4",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column income-card"
                    >
                      <Index.Box className="admin-dashboard-box token-card">
                        <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                          <Index.Box className="admin-dash-left">
                            <Index.Typography
                              className="admin-dash-price user-dash-price user-details-price"
                              variant="h1"
                              component="h1"
                            >
                              Total USDT (Exchange)
                            </Index.Typography>
                            <Index.Typography
                              className="admin-dash-text user-dash-text user-details-text"
                              variant="p"
                              component="p"
                            >
                              {users ? users?.exchangeUSDT?.toFixed(2) : 0}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 4",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column income-card"
                    >
                      <Index.Box className="admin-dashboard-box token-card">
                        <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                          <Index.Box className="admin-dash-left">
                            <Index.Typography
                              className="admin-dash-price user-dash-price user-details-price"
                              variant="h1"
                              component="h1"
                            >
                              Total Coin (Exchange)
                            </Index.Typography>
                            <Index.Typography
                              className="admin-dash-text user-dash-text user-details-text"
                              variant="p"
                              component="p"
                            >
                              {users ? users?.exchangeCoin?.toFixed(2) : 0}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="admin-dashboad-row res-admin-dashboad-row">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            className="display-row"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="admin-dashboad-row res-admin-dashboad-row mb-0">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-dashboard-box">
                        <Index.Typography className="admin-page-title">
                          Basic Details
                        </Index.Typography>

                        <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box basic-tabcard-main">
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 2",
                              lg: "span 2",
                            }}
                            className="grid-column basic-card-detail"
                          >
                            <Index.Typography>Date</Index.Typography>
                            <Index.Typography>
                              {Index.moment(users?.user?.createdAt).format(
                                "DD-MM-YYYY HH:mm"
                              )}
                            </Index.Typography>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 2",
                              lg: "span 2",
                            }}
                            className="grid-column basic-card-detail"
                          >
                            <Index.Typography>Full Name</Index.Typography>
                            <Index.Typography>
                              {users
                                ? users?.user?.first_name +
                                  " " +
                                  users?.user?.last_name
                                : "-"}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 2",
                              lg: "span 2",
                            }}
                            className="grid-column basic-card-detail"
                          >
                            <Index.Typography> User Name</Index.Typography>
                            <Index.Typography>
                              {users ? users?.user?.user_name : "-"}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 2",
                              lg: "span 2",
                            }}
                            className="grid-column basic-card-detail"
                          >
                            <Index.Typography> Email</Index.Typography>
                            <Index.Typography>
                              {users ? users?.user?.email : "-"}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 2",
                              lg: "span 2",
                            }}
                            className="grid-column basic-card-detail"
                          >
                            <Index.Typography>UID</Index.Typography>
                            <Index.Typography>
                              {users ? users?.user?.uid : "-"}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 2",
                              lg: "span 2",
                            }}
                            className="grid-column basic-card-detail"
                          >
                            <Index.Typography>Mobile Number</Index.Typography>
                            <Index.Typography>
                              {users ? users?.user?.mobile_number : "-"}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* wallet details */}
      <Index.Box className="admin-dashboad-row res-admin-dashboad-row">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            className="display-row"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="admin-dashboad-row res-admin-dashboad-row mb-0">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-dashboard-box">
                        <Index.Typography className="admin-page-title">
                          Wallet Address
                        </Index.Typography>

                        <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box basic-tabcard-main">
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 2",
                              lg: "span 2",
                            }}
                            className="grid-column basic-card-detail"
                          >
                            <Index.Typography>Wallet Address</Index.Typography>
                            <Index.Typography>
                              {users?.fbcData?.unique_id}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="admin-dashboad-row res-admin-dashboad-row">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            className="display-row"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="admin-dashboad-row res-admin-dashboad-row mb-0">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-dashboard-box">
                        <Index.Typography className="admin-page-title">
                          Wallet PrivateKey
                        </Index.Typography>

                        <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box basic-tabcard-main">
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 2",
                              lg: "span 2",
                            }}
                            className="grid-column basic-card-detail"
                          >
                            <Index.Typography>
                              Wallet Private key
                            </Index.Typography>
                            <Index.Typography>
                              {users?.fbcData?.unique_data}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="bg-white net-ex-tabbar">
        <TabContext value={value}>
          <TabList aria-label="Your Tab List">
            <Tab
              label="Network"
              value="tab1"
              onClick={() => {
                onHandleNetworkTab();
              }}
            />
            <Tab
              label="Exchange"
              value="tab2"
              onClick={() => {
                onHandleExchangeTab();
              }}
            />
          </TabList>

          {value == "tab1" && (
            <>
              <Index.FormControl>
                <Index.RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  value={radioValue}
                  onChange={handleRadioChange}
                  name="radio-buttons-group"
                >
                  <Index.FormControlLabel
                    value="stakingList"
                    control={<Index.Radio />}
                    label="Staking List"
                  />
                  <Index.FormControlLabel
                    value="walletStakingList"
                    control={<Index.Radio />}
                    label="Wallet staking List (Transfer)"
                  />
                  <Index.FormControlLabel
                    value="income"
                    control={<Index.Radio />}
                    label="Income"
                  />
                  <Index.FormControlLabel
                    value="team"
                    control={<Index.Radio />}
                    label="Team"
                  />
                </Index.RadioGroup>
              </Index.FormControl>
              <Index.Box>
                {radioValue == "stakingList" && (
                  <Index.Box className="admin-dashboard-list-row">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container user-details"
                    >
                      <Index.Table
                        className="main-table"
                        aria-label="simple table"
                      >
                        <Index.TableHead>
                          <Index.TableRow>
                            <Index.TableCell align="left">
                              Date && Time
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              Stake Id
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              Amount
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              Token
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody>
                          {staking?.length > 0 ? (
                            staking?.map((staking, index) => {
                              return (
                                <>
                                  <Index.TableRow key={staking?._id}>
                                    <Index.TableCell align="left">
                                      {Index.moment(staking?.createdAt).format(
                                        "DD-MM-YYYY HH:mm"
                                      )}
                                    </Index.TableCell>
                                    <Index.TableCell align="left">
                                      {staking?.stak_id ? staking?.stak_id : 0}
                                    </Index.TableCell>

                                    <Index.TableCell align="left">
                                      {staking?.total_amount
                                        ? staking?.total_amount
                                        : 0}
                                    </Index.TableCell>
                                    <Index.TableCell align="left">
                                      {staking?.total_token
                                        ? staking?.total_token?.toFixed(8)
                                        : 0}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                </>
                              );
                            })
                          ) : (
                            <Index.TableRow>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                colSpan={9}
                              >
                                No Record found
                              </Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                )}
                {radioValue === "walletStakingList" && (
                  <Index.Box className="admin-dashboard-list-row">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container user-details"
                    >
                      <Index.Table
                        className="main-table"
                        aria-label="simple table"
                      >
                        <Index.TableHead>
                          <Index.TableRow>
                            <Index.TableCell align="left">Date</Index.TableCell>
                            <Index.TableCell align="left">
                              {"Sender --> Receiver"}
                            </Index.TableCell>
                            <Index.TableCell align="left">USDT</Index.TableCell>
                            <Index.TableCell align="left">Coin</Index.TableCell>
                            <Index.TableCell align="left">Fees</Index.TableCell>
                            <Index.TableCell align="left">Type</Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody>
                          {networkTransfer?.length > 0 ? (
                            networkTransfer?.map((items, index) => {
                              return (
                                <>
                                  <Index.TableRow key={items?._id}>
                                    <Index.TableCell align="left">
                                      {Index.moment(items?.createdAt).format(
                                        "DD-MM-YYYY HH:mm"
                                      )}
                                    </Index.TableCell>
                                    <Index.TableCell align="left">
                                      {items?.sender
                                        ? items?.sender?.user_name
                                        : "-"}{" "}
                                      {"-->"}{" "}
                                      {items?.receiver
                                        ? items?.receiver?.user_name
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell align="left">
                                      {items?.amount ? items?.amount.toFixed(6) : 0}
                                    </Index.TableCell>
                                    <Index.TableCell align="left">
                                      {items?.token_value
                                        ? items?.token_value?.toFixed(6)
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell align="left">
                                      {items?.withdraw_fees
                                        ? items?.withdraw_fees
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell align="left">
                                      {items?.type
                                        ? items?.type === "SelfExchange"
                                          ? "Network to exchange (Self)"
                                          : items?.type === "OthersExchange"
                                          ? "Network to exchange (Other)"
                                          : items?.type === "Others"
                                          ? "Network to network (Other)"
                                          : "-"
                                        : "-"}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                </>
                              );
                            })
                          ) : (
                            <Index.TableRow>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                colSpan={9}
                              >
                                No Record found
                              </Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                )}
                {radioValue === "income" && (
                  <Index.Box className="admin-dashboard-list-row">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container user-details"
                    >
                      <Index.Table
                        className="main-table"
                        aria-label="simple table"
                      >
                        <Index.TableHead>
                          <Index.TableRow>
                            <Index.TableCell align="left">Date</Index.TableCell>

                            <Index.TableCell align="left">Type</Index.TableCell>
                            <Index.TableCell align="left">
                              Income
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              User Name
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody>
                          {networkIncome?.length > 0 ? (
                            networkIncome?.map((items, index) => {
                              return (
                                <>
                                  <Index.TableRow key={items?._id}>
                                    <Index.TableCell align="left">
                                      {Index.moment(items?.createdAt).format(
                                        "DD-MM-YYYY HH:mm"
                                      )}
                                    </Index.TableCell>
                                    <Index.TableCell align="left">
                                      {items?.type ? items?.type : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell align="left">
                                      {items?.income ? items?.income : 0}
                                    </Index.TableCell>
                                    <Index.TableCell align="left">
                                      {items?.income_by?.user_name
                                        ? items?.income_by?.user_name
                                        : "-"}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                </>
                              );
                            })
                          ) : (
                            <Index.TableRow>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                colSpan={9}
                              >
                                No Record found
                              </Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                )}
                {radioValue === "team" && (
                  <Index.Box className="admin-dashboard-list-row">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container user-details"
                    >
                      <Index.Table
                        className="main-table"
                        aria-label="simple table"
                      >
                        <Index.TableHead>
                          <Index.TableRow>
                            <Index.TableCell align="left">Name</Index.TableCell>
                            <Index.TableCell align="left">
                              Email
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              Mobile Number
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              Level
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              Status
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody>
                          {networkTeam?.length > 0 ? (
                            networkTeam?.map((items, index) => {
                              return (
                                <>
                                  <Index.TableRow key={items?._id}>
                                    <Index.TableCell align="left">
                                      {items
                                        ? items?.first_name +
                                          " " +
                                          items?.last_name
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell align="left">
                                      {items?.email ? items?.email : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell align="left">
                                      {items?.mobile_number
                                        ? items?.mobile_number
                                        : 0}
                                    </Index.TableCell>
                                    <Index.TableCell align="left">
                                      {items?.level ? items?.level : 0}
                                    </Index.TableCell>
                                    <Index.TableCell align="left">
                                      <Index.Box
                                        className={
                                          items.is_active
                                            ? "user-details-status-active"
                                            : "user-details-status-deactive"
                                        }
                                      >
                                        {items?.is_active == true
                                          ? "Active"
                                          : "Deactive"}
                                      </Index.Box>
                                    </Index.TableCell>
                                  </Index.TableRow>
                                </>
                              );
                            })
                          ) : (
                            <Index.TableRow>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                colSpan={9}
                              >
                                No Record found
                              </Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                )}
              </Index.Box>
            </>
          )}

          {value == "tab2" && (
            <>
              <Index.FormControl>
                <Index.RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  value={exchangeRadioValue}
                  onChange={handleExchangeRadioChange}
                  name="radio-buttons-group"
                >
                  <Index.FormControlLabel
                    value="depositExchange"
                    control={<Index.Radio />}
                    label="Deposit (Exchange)"
                  />
                  <Index.FormControlLabel
                    value="transferList"
                    control={<Index.Radio />}
                    label="Transfer List (Exchange)"
                  />
                  <Index.FormControlLabel
                    value="withdrawList"
                    control={<Index.Radio />}
                    label="Withdraw List (Exchange)"
                  />
                </Index.RadioGroup>
              </Index.FormControl>
              {exchangeRadioValue == "depositExchange" && (
                <Index.Box className="admin-dashboard-list-row">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container user-details"
                  >
                    <Index.Table
                      className="main-table"
                      aria-label="simple table"
                    >
                      <Index.TableHead>
                        <Index.TableRow>
                          <Index.TableCell align="left">Date</Index.TableCell>
                          <Index.TableCell align="left">
                            Hash(Key)
                          </Index.TableCell>
                          <Index.TableCell align="left">Value</Index.TableCell>
                          <Index.TableCell align="left">To</Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody>
                        {exchangeDeposit?.length > 0 ? (
                          exchangeDeposit?.map((items, index) => {
                            return (
                              <>
                                <Index.TableRow key={items?._id}>
                                  <Index.TableCell align="left">
                                    {Index.moment(items?.createdAt).format(
                                      "DD-MM-YYYY HH:mm"
                                    )}
                                  </Index.TableCell>
                                  <Index.TableCell align="left">
                                    {formatData(
                                      items?.transactionHash
                                        ? items?.transactionHash
                                        : "-"
                                    )}
                                  </Index.TableCell>
                                  <Index.TableCell align="left">
                                    {items?.value
                                      ? items?.value?.toFixed(8)
                                      : 0}
                                  </Index.TableCell>
                                  <Index.TableCell align="left">
                                    {formatData(items?.to ? items?.to : "-")}
                                  </Index.TableCell>
                                </Index.TableRow>
                              </>
                            );
                          })
                        ) : (
                          <Index.TableRow>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="table-td"
                              colSpan={9}
                            >
                              No Record found
                            </Index.TableCell>
                          </Index.TableRow>
                        )}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                  <Index.Box className="pagination-main">
                    <Index.TablePagination
                      component="div"
                      page={exPage}
                      count={exCount}
                      onPageChange={exHandleChangePage}
                      rowsPerPage={exRowsPerPage}
                      rowsPerPageOptions={[15, 25, 50]}
                      onRowsPerPageChange={exHandleChangeRowsPerPage}
                    />
                  </Index.Box>
                </Index.Box>
              )}
              {exchangeRadioValue == "transferList" && (
                <Index.Box className="admin-dashboard-list-row">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container user-details"
                  >
                    <Index.Table
                      className="main-table"
                      aria-label="simple table"
                    >
                      <Index.TableHead>
                        <Index.TableRow>
                          <Index.TableCell align="left">Date</Index.TableCell>
                          <Index.TableCell align="left">
                            {"Sender --> Receiver"}
                          </Index.TableCell>
                          <Index.TableCell align="left">Coin Send</Index.TableCell>
                          <Index.TableCell align="left">Coin Receive</Index.TableCell>
                          <Index.TableCell align="left">Fees</Index.TableCell>
                          <Index.TableCell align="left">Type</Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody>
                        {exchangeTransfer?.length > 0 ? (
                          exchangeTransfer?.map((items, index) => {
                            return (
                              <>
                                <Index.TableRow key={items?._id}>
                                  <Index.TableCell align="left">
                                    {Index.moment(items?.createdAt).format(
                                      "DD-MM-YYYY HH:mm"
                                    )}
                                  </Index.TableCell>
                                  <Index.TableCell align="left">
                                    {items?.user_id
                                      ? items?.user_id?.user_name
                                      : "-"}{" "}
                                    {"-->"}{" "}
                                    {items?.transfer
                                      ? items?.transfer?.user_name
                                      : "-"}
                                  </Index.TableCell>
                                  <Index.TableCell align="left">
                                    {items?.token_requested
                                      ? items?.token_requested?.toFixed(8)
                                      : 0}
                                  </Index.TableCell>
                                  <Index.TableCell align="left">
                                    {items?.token_withdraw
                                      ? items?.token_withdraw?.toFixed(8)
                                      : 0}
                                  </Index.TableCell>
                                  <Index.TableCell align="left">
                                    {items?.withdraw_fees
                                      ? items?.withdraw_fees?.toFixed(8)
                                      : 0}
                                  </Index.TableCell>
                                  <Index.TableCell align="left">
                                    {items?.type
                                      ? items?.type == "OthersWithdraw"
                                        ? "Exchange to network (Other)"
                                        : items?.type == "SelfWithdraw"
                                        ? "Exchange to network (Self)"
                                        : "-"
                                      : "-"}
                                  </Index.TableCell>
                                </Index.TableRow>
                              </>
                            );
                          })
                        ) : (
                          <Index.TableRow>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="table-td"
                              colSpan={9}
                            >
                              No Record found
                            </Index.TableCell>
                          </Index.TableRow>
                        )}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
              )}
              {exchangeRadioValue == "withdrawList" && (
                <Index.Box className="admin-dashboard-list-row">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container user-details"
                  >
                    <Index.Table
                      className="main-table"
                      aria-label="simple table"
                    >
                      <Index.TableHead>
                        <Index.TableRow>
                          <Index.TableCell align="left">Date</Index.TableCell>
                          <Index.TableCell align="left">
                            {`Sender --> Receiver`}
                          </Index.TableCell>
                          <Index.TableCell align="left">Req.USDT/Send Coin</Index.TableCell>
                          <Index.TableCell align="left">Transfer Coin</Index.TableCell>
                          <Index.TableCell align="left">Fees</Index.TableCell>
                          <Index.TableCell align="left">Type</Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody>
                        {exchangeWithdraw?.length > 0 ? (
                          exchangeWithdraw?.map((exchangeWithdraw, index) => {
                            return (
                              <>
                                <Index.TableRow key={exchangeWithdraw?._id}>
                                  <Index.TableCell align="left">
                                    {Index.moment(
                                      exchangeWithdraw?.createdAt
                                    ).format("DD-MM-YYYY HH:mm")}
                                  </Index.TableCell>
                                  <Index.TableCell align="left">
                                    {`${
                                      exchangeWithdraw?.user_id
                                        ? exchangeWithdraw?.user_id?.user_name
                                        : "-"
                                    }
                            -->
                            ${
                              exchangeWithdraw?.transfer
                                ? exchangeWithdraw?.transfer?.user_name
                                : exchangeWithdraw?.user_id?.user_name
                            }`}
                                  </Index.TableCell>
                                  <Index.TableCell align="left">
                                    {exchangeWithdraw?.token_requested
                                      ? exchangeWithdraw?.token_requested?.toFixed(
                                          6
                                        )
                                      : 0}
                                  </Index.TableCell>
                                  <Index.TableCell align="left">
                                    {exchangeWithdraw?.token_withdraw
                                      ? exchangeWithdraw?.token_withdraw?.toFixed(
                                          6
                                        )
                                      : 0}
                                  </Index.TableCell>
                                  <Index.TableCell align="left">
                                    {exchangeWithdraw?.withdraw_fees
                                      ? exchangeWithdraw?.withdraw_fees?.toFixed(
                                          6
                                        )
                                      : 0}
                                  </Index.TableCell>
                                  <Index.TableCell align="left">
                                    {exchangeWithdraw?.type
                                      ? exchangeWithdraw?.type == "SelfWithdraw"
                                        ? "Exchange to network (Self)"
                                        : exchangeWithdraw?.type ==
                                          "OthersWithdraw"
                                        ? "Exchange to network (Other)"
                                        : "-"
                                      :exchangeWithdraw?.status}
                                  </Index.TableCell>
                                </Index.TableRow>
                              </>
                            );
                          })
                        ) : (
                          <Index.TableRow>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="table-td"
                              colSpan={9}
                            >
                              No Record found
                            </Index.TableCell>
                          </Index.TableRow>
                        )}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
              )}
            </>
          )}
          {radioValue === "stakingList" ||
          radioValue === "walletStakingList" ||
          radioValue === "income" ||
          exchangeRadioValue == "transferList" ||
          exchangeRadioValue == "withdrawList" ? (
            <>
              <Index.Box className="pagination-main">
                <Index.TablePagination
                  component="div"
                  page={page}
                  count={count}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[15, 25, 50]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Index.Box>
            </>
          ) : (
            ""
          )}
        </TabContext>
      </Index.Box>
    </>
  );
};

export default UserDetail;
