import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useSelector } from "react-redux";
import FbcLoading from "../../../../common/FbcLoading";
import { useLocation, useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copyTextToClipboard from "copy-text-to-clipboard";
import { toast } from "react-toastify";

export default function ViewPostRequest() {
  const AdminToken = useSelector((store) => store.admin.admintoken);
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location?.state?.row;
  console.log(userData, 852);

  let token = window.atob(AdminToken);

  const [loader, setLoader] = useState(false);
  const [modalOpen, setOpenModal] = useState(false);
  const [reason, setReason] = useState("");

  const handleReject = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleCopy = (link) => {
    console.log(link, "link");
    copyTextToClipboard(link);
    toast.success(" link Copied");

    // let copyText = document.getElementById("referral-link");
    // console.log(copyText, ":copyTextcopyText");
    // console.log(copyText.value, ":copyText", copyText.title);
    // copyText.select();
    document.execCommand("copy");
  };

  const handleSubmit = (status, reason) => {
    setLoader(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", userData?._id);
    urlencoded.append("status", status);
    if (reason) {
      urlencoded.append("reason", reason);
    }
    try {
      DataService(token)
        .post(API.Admin.ACTION_ON_SOCIAL_MEDIA, urlencoded)
        .then((res) => {
          if (res?.data?.status == 200) {
            console.log(res?.data?.data, 56);
            toast.success(res?.data?.message);
            navigate("/admin/social-media-post-request");
          }
          setLoader(false);
        });
    } catch (err) {
      console.log(err, ":errs");
    }
  };

  if (loader) {
    return <FbcLoading />;
  } else {
    return (
      <>
        <Index.Box className="dashboard-content add-user-containt bg-white">
          <Index.Box className="user-head-title">
            <Index.Typography
              className="admin-page-title"
              component="h2"
              variant="h2"
            >
              Social Media Links
            </Index.Typography>
          </Index.Box>

          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="view-social-main">
                    <Index.Box className="page-table-main social-table-list table-design-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                User name
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Link
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Status
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              ></Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {/* {postList.length > 0 ? (
                              postList
                                ?.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                ?.map((row) => {
                                 
                                  return ( */}

                            <Index.TableRow
                              // key={row._id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {userData?.discord_user_name
                                  ? userData?.discord_user_name
                                  : "-"}
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {userData?.discord_link
                                  ? userData?.discord_link
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {/* {userData?.status ? userData?.status : "-"} */}
                                {userData?.status
                                  ? userData?.status === "approved"
                                    ? "Approved"
                                    : userData?.status === "pending"
                                    ? "Pending"
                                    : userData?.status === "rejected"
                                    ? "Rejected"
                                    : "-"
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                <Index.Box className="res-primary-btn referral-link-btn">
                                  <Index.Box class="primary-btn-main copy-btn res-copt-text">
                                    {userData?.status == "approved" ? (
                                      <>
                                        <CopyToClipboard
                                          text={userData?.discord_link}
                                        >
                                          <Index.IconButton
                                            className="history-btn"
                                            disabled={!userData?.discord_link}
                                            onClick={() => {
                                              handleCopy(
                                                userData?.discord_link
                                              );
                                            }}
                                          >
                                            <Index.ContentCopyIcon />
                                          </Index.IconButton>
                                        </CopyToClipboard>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Index.Box>
                                </Index.Box>
                              </Index.TableCell>
                            </Index.TableRow>

                            <Index.TableRow
                              // key={row._id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {userData?.facebook_user_name
                                  ? userData?.facebook_user_name
                                  : "-"}
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {/* {userData?.facebook_link
                                  ? userData?.facebook_link
                                  : "-"} */}
                                  <a
                                  href={userData?.facebook_link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {userData?.facebook_link
                                    ? userData?.facebook_link
                                    : "-"}
                                </a>
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {userData?.status
                                  ? userData?.status === "approved"
                                    ? "Approved"
                                    : userData?.status === "pending"
                                    ? "Pending"
                                    : userData?.status === "rejected"
                                    ? "Rejected"
                                    : "-"
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                <Index.Box className="res-primary-btn referral-link-btn">
                                  <Index.Box class="primary-btn-main copy-btn res-copt-text">
                                    {userData?.status == "approved" ? (
                                      <>
                                        <CopyToClipboard
                                          text={userData?.facebook_link}
                                        >
                                          <Index.IconButton
                                            className="history-btn"
                                            disabled={!userData?.facebook_link}
                                            onClick={() => {
                                              handleCopy(
                                                userData?.facebook_link
                                              );
                                            }}
                                          >
                                            <Index.ContentCopyIcon />
                                          </Index.IconButton>
                                        </CopyToClipboard>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Index.Box>
                                </Index.Box>
                              </Index.TableCell>
                            </Index.TableRow>

                            <Index.TableRow
                              // key={row._id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {userData?.instagram_user_name
                                  ? userData?.instagram_user_name
                                  : "-"}
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {/* {userData?.instagram_link
                                  ? userData?.instagram_link
                                  : "-"} */}
                                <a
                                  href={userData?.instagram_link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {userData?.instagram_link
                                    ? userData?.instagram_link
                                    : "-"}
                                </a>
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {userData?.status
                                  ? userData?.status === "approved"
                                    ? "Approved"
                                    : userData?.status === "pending"
                                    ? "Pending"
                                    : userData?.status === "rejected"
                                    ? "Rejected"
                                    : "-"
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                <Index.Box className="res-primary-btn referral-link-btn">
                                  <Index.Box class="primary-btn-main copy-btn res-copt-text">
                                    {userData?.status == "approved" ? (
                                      <>
                                        <CopyToClipboard
                                          text={userData?.instagram_link}
                                        >
                                          <Index.IconButton
                                            className="history-btn"
                                            disabled={!userData?.instagram_link}
                                            onClick={() => {
                                              handleCopy(
                                                userData?.instagram_link
                                              );
                                            }}
                                          >
                                            <Index.ContentCopyIcon />
                                          </Index.IconButton>
                                        </CopyToClipboard>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Index.Box>
                                </Index.Box>
                              </Index.TableCell>
                            </Index.TableRow>

                            <Index.TableRow
                              // key={row._id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {userData?.twitter_user_name
                                  ? userData?.twitter_user_name
                                  : "-"}
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {/* {userData?.twitter_link
                                  ? userData?.twitter_link
                                  : "-"} */}
                                    <a
                                  href={userData?.twitter_link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {userData?.twitter_link
                                    ? userData?.twitter_link
                                    : "-"}
                                </a>
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {userData?.status
                                  ? userData?.status === "approved"
                                    ? "Approved"
                                    : userData?.status === "pending"
                                    ? "Pending"
                                    : userData?.status === "rejected"
                                    ? "Rejected"
                                    : "-"
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                <Index.Box className="res-primary-btn referral-link-btn">
                                  <Index.Box class="primary-btn-main copy-btn res-copt-text">
                                    {userData?.status == "approved" ? (
                                      <>
                                        <CopyToClipboard
                                          text={userData?.twitter_link}
                                        >
                                          <Index.IconButton
                                            className="history-btn"
                                            disabled={!userData?.twitter_link}
                                            onClick={() => {
                                              handleCopy(
                                                userData?.twitter_link
                                              );
                                            }}
                                          >
                                            <Index.ContentCopyIcon />
                                          </Index.IconButton>
                                        </CopyToClipboard>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Index.Box>
                                </Index.Box>
                              </Index.TableCell>
                            </Index.TableRow>

                            <Index.TableRow
                              // key={row._id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {userData?.youtube_user_name
                                  ? userData?.youtube_user_name
                                  : "-"}
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {/* {userData?.youtube_link
                                  ? userData?.youtube_link
                                  : "-"} */}
                                     <a
                                  href={userData?.youtube_link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {userData?.youtube_link
                                    ? userData?.youtube_link
                                    : "-"}
                                </a>
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {userData?.status
                                  ? userData?.status === "approved"
                                    ? "Approved"
                                    : userData?.status === "pending"
                                    ? "Pending"
                                    : userData?.status === "rejected"
                                    ? "Rejected"
                                    : "-"
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                <Index.Box className="res-primary-btn social-copy-link referral-link-btn">
                                  <Index.Box class="primary-btn-main copy-btn res-copt-text">
                                    {userData?.status == "approved" ? (
                                      <>
                                        <CopyToClipboard
                                          text={userData?.youtube_link}
                                        >
                                          <Index.IconButton
                                            className="history-btn"
                                            disabled={!userData?.youtube_link}
                                            onClick={() => {
                                              handleCopy(
                                                userData?.youtube_link
                                              );
                                            }}
                                          >
                                            <Index.ContentCopyIcon />
                                          </Index.IconButton>
                                        </CopyToClipboard>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Index.Box>
                                </Index.Box>
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                      {userData?.status == "approved" ? (
                        ""
                      ) : (
                        <>
                          <Index.Box className="social-approve-reject-button">
                            <Index.Button
                              className={
                                userData?.status !== "approved"
                                  ? "social-btn approve-btn"
                                  : "social-btn approve-btn-disabled"
                              }
                              disabled={userData?.status === "approved"}
                              onClick={() => handleSubmit("approved")}
                            >
                              Approve
                            </Index.Button>
                            <Index.Button
                              className={
                                userData?.status !== "approved"
                                  ? "social-btn reject-btn"
                                  : "social-btn reject-btn-disabled"
                              }
                              disabled={userData?.status === "approved"}
                              onClick={handleReject}
                            >
                              Reject
                            </Index.Button>
                          </Index.Box>
                        </>
                      )}
                    </Index.Box>
                  </Index.Box>{" "}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="dashboard-content referral-list-tree-btn">
            <Index.Typography
              className="admin-page-title res-referral-list-tree-btn"
              component="h2"
              variant="h2"
            >
              {" "}
              {userData?.reason ? <>Reason:{userData?.reason}</> : ""}
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        {modalOpen ? (
          <Index.Dialog
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="dialog-box"
          >
            <Index.Box className="border-gradient dialog-box-social">
              <Index.DialogTitle
                id="alert-dialog-title"
                className="dialog-title-social"
              >
                Give reason:
              </Index.DialogTitle>
              <Index.DialogContent>
                <Index.TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  type="text"
                  fullWidth
                  name="reason"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  variant="standard"
                />
              </Index.DialogContent>
              <Index.DialogActions>
                <Index.Box
                  className="btn-main res-btn-main login-btn-main primary-btn-main-comman "
                  mt={2}
                >
                  <Index.Button
                    className="add-user-btn  btn-dark-comman-main "
                    disableRipple
                    type="button"
                    onClick={() => handleSubmit("rejected", reason)}
                  >
                    Send
                  </Index.Button>
                </Index.Box>
              </Index.DialogActions>
            </Index.Box>
          </Index.Dialog>
        ) : (
          ""
        )}
      </>
    );
  }
}
