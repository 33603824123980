import React, { useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Index from "../../component/Index";
import RadialSeparators from "../RadialSeparators";
import { DataService } from "../../config/DataService";
import { API } from "../../config/Api";
import { toast } from "react-toastify";

export default function MiningProgress({
  progress,
  token,
  setIsMiningStart,
  isMiningStart,
  setIsMining,
}) {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getStakingIncomeByMining = async () => {
    await DataService(token)
      .get(API.User.MINING)
      .then((res) => {
        setStatus(res?.data?.message);
        handleClickOpen();
        setIsMining(true);
        setIsMiningStart(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setIsMining(true);
      });
  };
  function TokenProgress(props) {
    return (
      <Index.Box className="prog-wrapper">
        <Index.Box style={{ width: "200px" }}>{props.children}</Index.Box>
      </Index.Box>
    );
  }

  return (
    <>
      <TokenProgress label="Progressbar with separators" className="token-prog">
        <CircularProgressbarWithChildren
          value={isMiningStart && progress ? parseInt(progress) : 0}
          text={isMiningStart && progress ? `${parseInt(progress)}%` : 0}
          strokeWidth={10}
          styles={buildStyles({
            strokeLinecap: "butt",
          })}
        >
          <RadialSeparators
            count={12}
            style={{
              background: "#fff",
              width: "2px",
              // This needs to be equal to props.strokeWidth
              height: `${10}%`,
            }}
          />
          {!isMiningStart && (
            <Index.Box className="primary-btn-main white-paper-margin">
              <button
                className="btn-dark-comman pre-btn bg-gradiant"
                onClick={getStakingIncomeByMining}
              >
                Mining
              </button>
            </Index.Box>
          )}
        </CircularProgressbarWithChildren>
      </TokenProgress>
      <Index.Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialog-box"
      >
        <Index.DialogTitle
          id="alert-dialog-title"
          className="border-gradient dialog-title"
        >
          {status}
        </Index.DialogTitle>
      </Index.Dialog>
    </>
  );
}
