// import { useSelector, useDispatch } from "react-redux";
// import { Navigate, Outlet, useNavigate } from "react-router-dom";
// import { useEffect } from "react";
// import { DataService } from "../config/DataService";
// import { API } from "../config/Api";
// import { adminProfile } from "../redux/features/slices/Admin/AdminSlice";
// import { toast } from "react-toastify";

// const ProtectedAdminRoutes = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   // Check token expiry
//   const isValidToken = (accessToken) => {
//     if (!accessToken) return false;

//     return true;

//     // const decodedToken = jwtDecode(accessToken);
//     // const currentTime = Date.now() / 1000;
//     // if (decodedToken.exp > currentTime) {
//     //   // Set access token in axios instance
//     // }
//     // axios.defaults.headers.common.auth = token;
//     // return decodedToken.exp > currentTime;
//   };

//   useEffect(() => {
//     DataService(token)
//       .get(API.Admin.GET_ADMIN_PROFILE)
//       .then((res) => {
//         dispatch(adminProfile(res?.data?.data));
//       })
//       .catch((error) => {
//         if (error?.response?.data?.status == 401) {
//           // toast.error(error?.response?.data?.message);
//           navigate("/admin/login");
//         }
//       });
//   }, []);

//   const isLogin = useSelector((state) => state.admin.isAuthenticated);

//   const adminToken = useSelector((store) => store.admin.admintoken);
//   let token = window.atob(adminToken);

//   return (
//     <>
//       {isLogin && isValidToken(token) ? (
//         <Outlet />
//       ) : (
//         <Navigate to="/admin/login" />
//       )}
//     </>
//   );
// };

// export default ProtectedAdminRoutes;

import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { DataService } from "../config/DataService";
import { API } from "../config/Api";
import { adminProfile } from "../redux/features/slices/Admin/AdminSlice";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";

const ProtectedAdminRoutes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.admin.isAuthenticated);
  const adminToken = useSelector((store) => store.admin.admintoken);
  let token = window.atob(adminToken);
  // Check token expiry
  const isValidToken = (accessToken) => {
    if (!accessToken) return false;

    // const decodedToken = jwtDecode(accessToken);
    // const currentTime = Date.now() / 1000;

    // return decodedToken.exp > currentTime + 86400;
  };

  useEffect(() => {
    if (!token) {
      navigate("/admin/login");
    } else {
      DataService(token)
        .get(API.Admin.GET_ADMIN_PROFILE)
        .then((res) => {
          dispatch(adminProfile(res?.data?.data));
        })
        .catch((error) => {
          if (error?.response?.data?.status === 401) {
            navigate("/admin/login");
          }
        });
    }
  }, [navigate, dispatch]);

  return <>{isLogin ? <Outlet /> : <Navigate to="/admin/login" />}</>;
};
export default ProtectedAdminRoutes;
