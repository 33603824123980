import Routers from "./routes/Routes";

import "./assets/style/admin.css";
// import "./assets/style/style.css";

import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Index from "./container/Index";
import "./assets/style/global.css";
import "./container/admin/pages/dashboard/admindashboard.css";
import "./component/admin/defaultLayout/defaultLayout.css";
import "./container/admin/pages/privateSale/presaleround.css";
import "./container/admin/pages/user/userlist.css";
import "./container/admin/pages/user/adduser.css";
import "./container/admin/pages/waitlist/waitlist.css";
// import './component/admin/defaultLayout/defaultLayout.responsive.css';
// user side css
import "./component/user/defaultLayout/defaultlayout.css";
import "./container/user/pages/dashboardcontent/depositmodal.css";
import "./container/user/pages/referral/myteamreferral.css";
import "./container/user/pages/referral/referral.css";
import "./container/user/pages/dashboardcontent/dashboardcontent.css";
//
import "./container/user/auth/login/login.css";
import "./container/user/pages/home/home.css";
import "./container/user/pages/home/joinwaitlist.css";
import "./container/user/pages/setting/setting.css";

//
import "./container/admin/pages/waitlist/waitlistTab/generalsetting.css";
import "./container/admin/pages/waitlist/waitlistTab/kycdetail.css";
import "./container/admin/pages/setting/adminsetting.css";
import "./container/admin/pages/editprofile/profile.css";
import "./container/admin/pages/privateSale/privateadminsale.css";
import "./container/admin/pages/transactions/transactionlist.css";
import "./container/admin/pages/privateSale/presaleTab/dashboard.css";
import "./container/user/pages/dashboardcontent/depositmodal.css";
import "./container/user/pages/referral/myteamreferral.css";
import "./container/user/pages/referral/referral.css";
import "./container/user/pages/dashboardcontent/dashboardcontent.css";
import "./container/admin/pages/waitlist/waitlistTab/generalsetting.css";
import "./container/admin/pages/waitlist/waitlistTab/kycdetail.css";
import "./container/admin/pages/setting/adminsetting.css";
import "./container/admin/pages/editprofile/profile.css";
import "./container/admin/pages/privateSale/privateadminsale.css";
import "./container/admin/pages/transactions/transactionlist.css";
import "./container/admin/pages/privateSale/presaleTab/dashboard.css";
import "./container/admin/pages/dashboard/admindashboard.responsive.css";
import "./container/user/pages/kyc/kycresponse.css";
import "./assets/style/responsive.css";
import "./assets/style/global.responsive.css";
import "./container/admin/pages/transactions/transaction.responsive.css";
import "./container/admin/pages/setting/adminsetting.responsive.css";
import "./container/admin/pages/user/userlist.responsive.css";
import "./container/user/pages/team/Team.css";
import "./container/user/pages/userKyc/UserKyc.css";
import "./container/user/pages/userKyc/UserKycResponsive.css";

function App() {
  {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }

  return (
    <div className="App">
      {/* <BrowserRouter> */}
      <Index.ToastContainer />
      <Routers />
      {/* </BrowserRouter> */}
    </div>
  );
}

export default App;
