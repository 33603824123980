import {
  Box,
  FormHelperText,
  TextField,
  Typography,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextareaAutosize,
  Select,
  Menu,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination,
  InputLabel,
  FilledInput,
  Tooltip,
  Grid,
  FormGroup,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import copyTextToClipboard from "copy-text-to-clipboard";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Link } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Svg from "../assets/Svg";
import Png from "../assets/Png";
import Jpg from "../assets/Jpg";
import Video from "../assets/Video";

import Footer from "../component/user/defaultLayout/Footer";
// import UserSidebar from "../component/defaulLayout/user/UserSidebar";
import PrimaryButton from "../common/PrimaryButton";
// import GreyButton from "../component/common/GreyButton";
import GreyButton from "../common/GreyButton";
import TextButton from "../common/TextButton";
import WhiteBorderButton from "../common/WhiteBorderButton";
import GreenButton from "../common/GreenButton";
import PriceCard from "../common/PriceCard";
import PrimaryBorderButton from "../common/PrimaryBorderButton";
import PrivateSaleWallet from "../common/PrivateSaleWallet";
import OwlCarousel from "react-owl-carousel3";
import Countdown from "react-countdown";
import GoToTop from "../common/GoToTop";

// container index

import OutlineButton from "../common/OutlineButton";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  ReplaceCharsWithAsterisks,
  sortInAscending,
  sortInDescending,
  sortFieldInAscending,
  sortFieldInDescending,
  IdentifyAddressProof,
  validateInpField,
} from "../common/CommonFun";
import Stack from "@mui/material/Stack";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { Export_excel } from "../common/ExportAll";
import { IOSSwitch } from "../common/IosSwitch";
import { NetworkLink } from "../common/NetworkLink";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ErrorShow from "../common/ErrorShow";
import { selectNetwork } from "../common/NetworkLink";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import MuiPhoneInput from "material-ui-phone-number";
import VerifiedIcon from "@mui/icons-material/Verified";
import TokenPreSaleProgress from "../common/TokenPreSaleProgress";
import { BpCheckbox } from "../common/BpCheckBox";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import MiningProgress from "../common/miningCounter/MiningProgress";

export default {
  Svg,
  Png,
  Link,
  Button,
  toast,
  ToastContainer,
  TabContext,
  BpCheckbox,
  useNavigate,
  useDispatch,
  useSelector,
  useLocation,
  MuiPhoneInput,
  MoreHorizIcon,
  NetworkLink,
  Stack,
  DesktopDatePicker,
  LocalizationProvider,
  AdapterDayjs,
  DatePicker,
  FormGroup,
  Typography,
  copyTextToClipboard,
  Tooltip,
  List,
  ListItem,
  PrimaryButton,
  OutlineButton,
  Footer,
  TextField,
  FormHelperText,
  TextareaAutosize,
  ReplaceCharsWithAsterisks,
  Checkbox,
  Countdown,
  OwlCarousel,
  QrCodeScannerIcon,
  MailOutlineIcon,
  ContentCopyIcon,
  Box,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  VisibilityOff,
  Visibility,
  Link,
  List,
  ListItem,
  Svg,
  Png,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextareaAutosize,
  Select,
  Menu,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  PrivateSaleWallet,
  ArrowForwardIosSharpIcon,
  MuiAccordion,
  MuiAccordionSummary,
  MuiAccordionDetails,
  InputLabel,
  FilledInput,
  VerifiedIcon,

  // components
  Footer,
  // UserSidebar,
  PrimaryButton,
  GreyButton,
  TextButton,
  WhiteBorderButton,
  GreenButton,
  PriceCard,
  PrimaryBorderButton,
  Video,
  TablePagination,
  GoToTop,
  Grid,
  Jpg,
  Export_excel,
  IOSSwitch,
  CKEditor,
  ClassicEditor,
  ErrorShow,
  selectNetwork,
  sortInAscending,
  sortInDescending,
  sortFieldInAscending,
  sortFieldInDescending,
  moment,
  IdentifyAddressProof,
  validateInpField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ExpandMoreIcon,
  TokenPreSaleProgress,
  TabList,
  TabPanel,
  MiningProgress,
};
