import React, { useState } from "react";
import Index from "../../Index";

export default function Footer() {
  return (
    <>
      <Index.Box className="footer-main-content"></Index.Box>

      <Index.Box className="bottom-bg-footer">
        <Index.Box className="container">
          <Index.Box className="footer-set">
            <Index.Box className="pd-footer-copy">
              <Index.Typography
                component="p"
                variant="p"
                className="copyright-title"
              >
                Copyright © 2023, FBC All Rights Reserved.
              </Index.Typography>
            </Index.Box>
            <Index.Box>
              <Index.List className="footer-ul">
                {/* <Index.ListItem className="header-listitem-li">
                  <Index.Link
                    className="header-redirect-link"
                    href="#section-home"
                  >
                    <Index.Typography component="p" variant="p">
                      About
                    </Index.Typography>
                  </Index.Link>
                </Index.ListItem> */}
                <Index.ListItem className="header-listitem-li">
                  <Index.Link
                    className="header-redirect-link"
                    href="http://farmarbrightcoin.com/terms-and-conditions/"
                    target="_blank"
                  >
                    <Index.Typography component="p" variant="p">
                      Terms & Condition
                    </Index.Typography>
                  </Index.Link>
                </Index.ListItem>
                <Index.ListItem className="header-listitem-li res-header-listitem-li">
                  <Index.Link
                    className="header-redirect-link"
                    href="http://farmarbrightcoin.com/privacy-policy/"
                    target="_blank"
                  >
                    <Index.Typography component="p" variant="p">
                      Privacy
                    </Index.Typography>
                  </Index.Link>
                </Index.ListItem>
              </Index.List>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
