import backaadhar from "./images/png/backaadhar.jpg";
import frontaadhar from "./images/png/frontaadhar.jpg";
import ResponseImg from "./images/jpg/response.jpg";
import CommingSoon from "./images/jpg/comming_soon.jpg";

const Jpg = {
  backaadhar,
  frontaadhar,
  ResponseImg,
  CommingSoon,
};

export default Jpg;
