import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";

// modal
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CircularProgress } from "@mui/material";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { getWalletDataList } from "../../../../redux/features/slices/Admin/AdminService";
import Index from "../../../Index";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  padding: "15px",
};
const AllIncome = () => {
  const details = useSelector((store) => store?.admin?.userDetail);
  let userDetails = JSON?.parse(window?.atob(details));
  const token = userDetails?.token;
  const userProfile = useSelector((state) => state?.admin?.userProfile);
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [verifyData, setVerifyData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [withdrawType, setWithdrawType] = useState("OthersExchange");
  const [open, setOpen] = useState(false);
  const [storeValue, setStoreValue] = useState({});
  const [walletDetails, setWalletDetails] = useState({});
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const initialValues = {
    user_name: "",
    amount: "",
    type: "",
    income_type: "",
  };

  const transferFbc = async (value) => {
    try {
      if (value) {
        const transferData = new URLSearchParams();
        transferData.append("user_name", value);

        const res = await DataService(token).post(
          API.User.WITHDRAW_VERIFY_USER,
          transferData
        );

        if (res?.data?.status === 200 && res?.data?.data?.user_name) {
          toast.success(res?.data?.message);
          setVerifyData(res?.data?.data?.uid);
        }
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const WithdrawCryptoSchema = Yup.object().shape({
    user_name: Yup.string().required("Please enter user name"),
    amount: Yup.number()
      .typeError("Only numbers are allowed")
      .required("Please enter amount"),
    income_type: Yup.string().required("Please select income type"),
    type: Yup.string().required("Please select transfer type"),
  });

  const confirmationModalOpen = async () => {
    const data = new URLSearchParams();
    data.append("withdraw_amount", storeValue?.amount);
    if (storeValue?.type == "OthersExchange" && verifyData) {
      data.append("transfer_to", verifyData);
    }
    if (storeValue?.type == "SelfExchange" && verifyData) {
      data.append("transfer_to", verifyData);
    }

    data.append("income_type", storeValue?.income_type);
    data.append("type", storeValue?.type);

    // second api

    const data2 = new URLSearchParams();
    if (storeValue?.type == "Others" && verifyData) {
      data2.append("transfer_to", verifyData);
    }
    data2.append("withdraw_amount", storeValue?.amount);
    data2.append("income_type", storeValue?.income_type);
    data2.append("type", storeValue?.type);

    if (
      storeValue?.type == "OthersExchange" ||
      storeValue?.type == "SelfExchange"
    ) {
      await DataService(token)
        .post(API.User.WITHDRAW_NETWORK, data)
        .then((res) => {
          setIsLoading(false);
          if (res?.data?.status == 200) {
            toast.success(res?.data?.message);
            dispatch(getWalletDataList(token));
            setVerifyData("");
            setOpen(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error?.response?.data?.message);
          setOpen(false);
        });
    }
    if (storeValue?.type == "Others") {
      await DataService(token)
        .post(API.User.Withdraw_user, data2)
        .then((res) => {
          setIsLoading(false);
          toast.success(res?.data?.message);
          dispatch(getWalletDataList(token));
          setVerifyData("");
          setOpen(false);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error?.response?.data?.message);
          setOpen(false);
        });
    }

    if (withdrawType !== "SelfExchange") {
      formikRef.current.setFieldValue("user_name", "");
    }
    formikRef.current.setFieldValue("amount", "");
    getWalletDetails();
  };

  const getWalletDetails = async () => {
    await DataService(token)
      .get(API.User.USER_WALLET)
      .then((res) => {
        setWalletDetails(res?.data?.data);
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };

  useEffect(() => {
    getWalletDetails();
  }, []);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    handleOpen();
    setStoreValue(values);
  };

  return (
    <>
      {/* modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="confirmation-box">
          <Box sx={style} className="confirmation-btn">
            <Typography
              id="modal-modal-description"
              sx={{ mt: 1, textAlign: "center", mb: 2 }}
            >
              Are you sure you want to withdraw?
            </Typography>
            <Index.Box className="con-btn ">
              <Button
                onClick={confirmationModalOpen}
                style={{
                  color: "white",
                  backgroundColor: "#00235f",
                  fontFamily: "system-ui",
                  fontSize: 13,
                  padding: 5,
                }}
              >
                Yes
              </Button>
              <Button
                onClick={() => {
                  handleClose();
                  setIsLoading(false);
                }}
                style={{
                  color: "white",
                  backgroundColor: "red",
                  fontSize: 12,
                  fontFamily: "system-ui",
                  marginLeft: 5,
                }}
              >
                No
              </Button>
            </Index.Box>
          </Box>
        </Box>
      </Modal>
      <Formik
        enableReinitialize
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={WithdrawCryptoSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Index.Box className="crypto-token-form withdraw-token-form">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                >
                  <>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box ">
                        <Index.FormHelperText className="form-lable">
                          Select Income
                        </Index.FormHelperText>
                        <Index.Box className="dropdown-box">
                          <Index.FormControl
                            fullWidth
                            className="form-control drop-form-control"
                          >
                            <Index.Select
                              className="dropdown-select drop-select"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="income_type"
                              onChange={(event) => {
                                setFieldValue(
                                  "income_type",
                                  event.target.value
                                );
                                // setFieldTouched("income_type", true);
                                // setFieldTouched("income_type", false);
                              }}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              value={values.income_type}
                              error={
                                touched.income_type &&
                                Boolean(errors.income_type)
                              }
                              renderValue={(value) => {
                                return value !== "" ? (
                                  value
                                ) : (
                                  <em>Select Income</em>
                                );
                              }}
                            >
                              <Index.MenuItem disabled value="">
                                <em>Select Income</em>
                              </Index.MenuItem>
                              <Index.MenuItem value="stake">
                                Stake
                              </Index.MenuItem>

                              <Index.MenuItem value="teamBonus">
                                Team Bonus
                              </Index.MenuItem>
                              <Index.MenuItem value="teamLevel">
                                Team Level
                              </Index.MenuItem>
                              <Index.MenuItem value="treePlant">
                                Tree Plant
                              </Index.MenuItem>
                              <Index.MenuItem value="directBonus">
                                Direct Bonus
                              </Index.MenuItem>
                            </Index.Select>

                            {touched.income_type && errors.income_type && (
                              <Index.FormHelperText
                                style={{ color: "#d32f2f", fontSize: "11px" }}
                              >
                                {errors.income_type}
                              </Index.FormHelperText>
                            )}
                          </Index.FormControl>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box ">
                        <Index.FormHelperText className="form-lable">
                          Select Transfer
                        </Index.FormHelperText>
                        <Index.Box className="dropdown-box">
                          <Index.FormControl className="form-control drop-form-control">
                            <Index.Select
                              className="dropdown-select"
                              name="type"
                              value={values.type}
                              onChange={(event) => {
                                setFieldValue("type", event.target.value);
                                if (event.target.value == "SelfExchange") {
                                  setFieldValue(
                                    "user_name",
                                    userDetails?.user_name
                                  );
                                } else {
                                  setFieldValue("user_name", "");
                                }
                                // setFieldTouched("type", true);
                                setFieldTouched("type", false);
                              }}
                              displayEmpty
                              inputProps={{
                                "aria-label": "Without label",
                              }}
                              error={touched.type && Boolean(errors.type)}
                              renderValue={(value) => {
                                return value !== "" ? (
                                  value
                                ) : (
                                  <em>Select Transfer</em>
                                );
                              }}
                            >
                              <Index.MenuItem disabled value="">
                                <em>Select Transfer</em>
                              </Index.MenuItem>
                              <Index.MenuItem
                                value={"OthersExchange"}
                                className="menuitem"
                              >
                                With Exchange Others User
                              </Index.MenuItem>
                              <Index.MenuItem
                                value={"Others"}
                                className="menuitem"
                              >
                                With Network Others User
                              </Index.MenuItem>
                              <Index.MenuItem
                                value={"SelfExchange"}
                                className="menuitem"
                              >
                                Withdraw to Exchange
                              </Index.MenuItem>
                            </Index.Select>

                            {touched.type && errors.type && (
                              <Index.FormHelperText
                                style={{ color: "#d32f2f", fontSize: "11px" }}
                              >
                                {errors.type}
                              </Index.FormHelperText>
                            )}
                          </Index.FormControl>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box ">
                      <Index.Box className="typography-flex verify-user">
                        <Index.FormHelperText className="form-lable">
                          User Name
                        </Index.FormHelperText>
                        {values?.type != "SelfExchange" ? (
                          <>
                            <Index.FormHelperText className="form-lable">
                              {verifyData === "" ? (
                                <>
                                  <NavLink
                                    className="transfer-verify"
                                    onClick={() => {
                                      transferFbc(values?.user_name);
                                    }}
                                  >
                                    Click to verify
                                  </NavLink>
                                </>
                              ) : (
                                <>
                                  <NavLink className="transfer-verify-verify">
                                    verify
                                  </NavLink>
                                </>
                              )}
                            </Index.FormHelperText>
                          </>
                        ) : (
                          ""
                        )}
                      </Index.Box>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          name="user_name"
                          className="form-control"
                          placeholder=""
                          autocomplete="off"
                          onBlur={handleBlur}
                          value={values?.user_name}
                          // value={
                          //   values?.type == "SelfExchange"
                          //     ? userDetails?.user_name
                          //     : values?.user_name
                          // }
                          onChange={(e) => {
                            setFieldValue("user_name", e.target.value);
                            if (e.target.value === "") {
                              setVerifyData("");
                            }
                            if (e.target.value !== verifyData?.user_name) {
                              setVerifyData("");
                            }
                          }}
                          helperText={touched.user_name && errors.user_name}
                          error={Boolean(errors.user_name && touched.user_name)}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box">
                      <Index.FormHelperText className="form-lable">
                        Amount
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          name="amount"
                          value={values.amount}
                          id="fullWidth"
                          className="form-control"
                          placeholder=""
                          autocomplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={touched.amount && errors.amount}
                          error={Boolean(errors.amount && touched.amount)}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box input-box-panel">
                      <Index.Box className="available-token-sec">
                        <Index.Typography
                          className="available-titles"
                          variant="p"
                          component="p"
                        >
                          Available Balance
                        </Index.Typography>
                        <Index.Typography
                          className="available-data"
                          variant="p"
                          component="p"
                        >
                          {values?.income_type === "stake"
                            ? walletDetails?.stak_income?.toFixed(2)
                            : values?.income_type === "teamBonus"
                            ? walletDetails?.team_bonus_income?.toFixed(2)
                            : values?.income_type === "teamLevel"
                            ? walletDetails?.team_level_income?.toFixed(2)
                            : values?.income_type === "treePlant"
                            ? walletDetails?.tree_plant_income?.toFixed(2)
                            : values?.income_type === "directBonus"
                            ? walletDetails?.direct_bonus_income?.toFixed(2)
                            : "-"}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="btn-main-primary withdraw-main-btn-btn">
                      <Index.Button
                        className="btn-primary withdraw-btn"
                        // type="submit"
                        onClick={() => {
                          if (walletDetails?.total_income == 0) {
                            toast.error(
                              "You do not have balance in your wallet to Withdraw"
                            );
                          } else {
                            handleSubmit();
                          }
                        }}
                        disableRipple
                      >
                        {!isLoading ? (
                          "Withdraw"
                        ) : (
                          <>
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={24}
                            />
                          </>
                        )}
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AllIncome;
