import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useSelector } from "react-redux";
import FbcLoading from "../../../../common/FbcLoading";

export default function ActiveUserList() {
  const navigate = useNavigate();
  const AdminToken = useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);

  // All states
  const [activeUser, setActiveUser] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [activeClass, setActiveClass] = useState(null);

  // Start Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [count, setCount] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // End Pagination

  const handleClick = (divIndex) => {
    setActiveClass(divIndex);
  };

  const getActiveUser = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("pageNumber", page + 1);
    urlencoded.append("pageSize", rowsPerPage);
    urlencoded.append("search", filterData);
    setLoader(true);
    await DataService(token)
      .post(API.Admin.GET_ACTIVE_USER, urlencoded)
      .then((res) => {
        setActiveUser(res?.data?.data?.userList);
        //setFilterData(res?.data?.data?.userList);
        setCount(res?.data?.data?.countOfUser);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
    setLoader(false);
  };
  const handleSendWithDrawFbc = async (id, amount, type) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    urlencoded.append("amount", amount);
    urlencoded.append("type", type);
    setLoader(true);
    await DataService(token)
      .post(API.Admin.SEND_FBC, urlencoded)
      .then((res) => {
        Index.toast.success(res?.data?.message);
        getActiveUser();
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  useEffect(() => {
    getActiveUser();
  }, [page, rowsPerPage]);

  const handleSearch = (event) => {
    setFilterData(event.target.value);
  };

  const handleView = (id) => {
    navigate(`/admin/user_detail/${id}`);
  };
  if (loader) {
    return <FbcLoading></FbcLoading>;
  } else {
    return (
      <div>
        <Index.Box className="dashboard-content add-user-containt bg-white">
          <Index.Box className="user-head-title">
            <Index.Typography
              className="admin-page-title"
              component="h2"
              variant="h2"
            >
              Active User List
            </Index.Typography>

            <Index.Box className="preasale-search">
              <Index.Box className="user-search-main cus-search">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      value={filterData}
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search..."
                      onChange={handleSearch}
                    />
                    <img
                      src={Index.Svg.greysearch}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                    <Index.Button
                      onClick={() => {
                        getActiveUser();
                      }}
                      sx={{ marginRight: 2 }}
                      variant="contained"
                    >
                      Search
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="user-waitlist-main">
                    <Index.Box className="page-table-main active-user-list-table table-design-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                onClick={() => handleClick(2)}
                              >
                                <Index.Box className="create-hover">
                                  User name{" "}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                onClick={() => handleClick(2)}
                              >
                                <Index.Box className="create-hover">
                                  Full name{" "}
                                </Index.Box>
                              </Index.TableCell>
                              {/* <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                onClick={() => handleClick(3)}
                              >
                                <Index.Box className="create-hover">
                                  Email{" "}
                                </Index.Box>
                              </Index.TableCell> */}
                              {/* <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Mobile Number
                              </Index.TableCell> */}
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Stake Coin
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                UnStake Coin
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Exchange FBC
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Wallet FBC
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Wallet USDT
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Difference
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {activeUser?.length > 0 ? (
                              activeUser.map((row, index) => {
                                let diff =
                                  Number(row?.coin?.toFixed(3)) +
                                  Number(row?.unstakeCoin?.toFixed(3)) +
                                  Number(row?.exchangeCoin?.toFixed(3)) -
                                  Number(row?.walletFbc?.toFixed(3)).toFixed(3);
                                return (
                                  <Index.TableRow
                                    key={index}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Tooltip
                                        title={row?.user_name}
                                        arrow
                                        placement="top"
                                      >
                                        {" "}
                                        {row?.user_name ? row?.user_name : "-"}
                                      </Index.Tooltip>
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Box
                                        className="username-bold"
                                        onClick={() => {
                                          handleView(row?._id);
                                        }}
                                        sx={{ cursor: "pointer" }}
                                      >
                                        {row
                                          ? row.first_name
                                            ? row.first_name +
                                              " " +
                                              (row.last_name || "")
                                            : "-"
                                          : "-"}
                                      </Index.Box>
                                    </Index.TableCell>
                                    {/* <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Tooltip
                                        title={row?.email}
                                        arrow
                                        placement="top"
                                      >
                                        <Index.Box className="email-ellip">
                                          {row?.email ? row?.email : "-"}
                                        </Index.Box>
                                      </Index.Tooltip>
                                    </Index.TableCell> */}
                                    {/* <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.mobile_number
                                        ? row?.mobile_number
                                        : 0}
                                    </Index.TableCell> */}

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.coin ? row?.coin?.toFixed(3) : 0}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.unstakeCoin
                                        ? row?.unstakeCoin?.toFixed(3)
                                        : 0}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.exchangeCoin
                                        ? row?.exchangeCoin?.toFixed(3)
                                        : 0}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.walletFbc
                                        ? row?.walletFbc?.toFixed(3)
                                        : 0}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.walletUsdt
                                        ? row?.walletUsdt?.toFixed(3)
                                        : 0}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {diff.toFixed(3)}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {diff > 0.0 ? (
                                        <Index.Button
                                          onClick={() => {
                                            handleSendWithDrawFbc(
                                              row?._id,
                                              diff,
                                              "send"
                                            );
                                          }}
                                          sx={{ marginRight: 2 }}
                                          variant="contained"
                                        >
                                          Send
                                        </Index.Button>
                                      ) : (
                                        ""
                                      )}

                                      {diff < -1 ? (
                                        <Index.Button
                                          onClick={() => {
                                            handleSendWithDrawFbc(
                                              row?._id,
                                              diff,
                                              "withdraw"
                                            );
                                          }}
                                          sx={{ marginRight: 2 }}
                                          variant="contained"
                                        >
                                          Withdraw
                                        </Index.Button>
                                      ) : (
                                        ""
                                      )}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                );
                              })
                            ) : (
                              <Index.TableRow>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  colSpan={9}
                                >
                                  No Record found
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>{" "}
                  <Index.Box className="pagination-main">
                    <Index.TablePagination
                      component="div"
                      page={page}
                      count={count}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[15, 25, 50, 100]}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelDisplayedRows={(page) =>
                        `Records ${page.from} to ${
                          page.to === -1 ? page.count : page.to
                        } of ${count}`
                      }
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </div>
    );
  }
}
