const API = {
  Admin: {
    ADMIN_LOGIN: "/admin/login",
    FORGOT_PASSWORD: "/admin/forgot-password",
    ADMIN_RESET_PASSWORD: "/admin/reset-password",
    GET_WITHDRAW_REQ_LIST: "/admin/withdraw-list",
    GET_WITHDRAW_LIST: "/admin/withdraw-request-list",
    TOKEN_LIST: "/admin/get-tokens",
    CATEGORY_LIST: "/admin/get-category",
    CATEGORY_ACTION: "/admin/category-action",
    UPDATE_TOKEN: "admin/update-token",
    TOKEN_ACTION: "admin/tokens-action",
    SYNC_TOKEN: "admin/sync-tokens",
    GET_USER_LIST: "admin/getuser-list",
    GET_SINGLE_USER_INFO: "admin/fetch-personal-info",
    USER_REGISTER: "user/register",
    USER_ADD_EDIT: "admin/add-edit-user",
    USER_REMOVE: "admin/remove-user",
    CHANGE_PASSWORD: "/admin/change-password",
    PROFILE_DATA: "/admin/get-profile",
    COUNTRY_LIST: "/admin/get-country-list",
    GET_DASHBOARD_DETAIL: "admin/get-dashboard",
    ADD_EDIT_PRIVATE_SALE: "/admin/add-edit-private-sale",
    GET_PRIVATE_SALE_LIST: "/admin/get-private-sale-list",
    GET_SINGLE_PRIVATE_SALE: "/admin/get-private-sale-id",
    REMOVE_PRIVATE_SALE: "/admin/remove-Private-Sale",
    GET_WAITLIST: "/admin/get-users",
    GET_USER_DETAIL: "/admin/user-details",
    GET_USER_NETWORK_TRANSFER: "/admin/user-network-transfer",
    GET_USER_INCOME: "/admin/user-income",
    GET_USER_TEAM: "/admin/user-team",
    GET_STAKING_LIST: "/admin/user-staking",

    GET_USER_EXCHANGE_TRANSFER: "/admin/user-exchange-transfer",
    GET_USER_EXCHANGE_DEPOSIT: "/admin/user-exchange-deposit",
    GET_USER_EXCHANGE_WITHDRAW: "/admin/user-exchange-withdraw",

    SEND_MAIL: "/admin/mail-sent",
    EDIT_PROFILE: "/admin/edit-details",
    GET_ADMIN_PROFILE: "admin/details",
    ADD_USER: "/admin/add-user",
    EDIT_USER: "/admin/edit-user",
    GET_USER: "/admin/get-user",
    DISABLE_USER: "/admin/update-user",
    ADMIN_SETTING: "/admin/update-setting",
    GET_SETTING: "/admin/get-setting",
    GET_TOTAL_TOKENS: "/admin/total-value",
    GET_TRANSCATOIN_LIST: "/admin/get-transactions",
    ADD_EDIT_CMS: "/admin/add-edit-cms",
    GRAPH_DETAILS: "/admin/graph",
    USER_ALL_DETAILS: "/admin/user-details",
    KYC_LIST: "/admin/kyc-list",
    ADD_EDIT_KYC: "/admin/add-edit-kyc",
    KYC_DETAILS: "/admin/kyc-details",
    KYC_VERIFICATION: "/admin/kyc-verification",
    WITHOUT_REFERRAL_LIST: "/admin/without-referral-list",
    UPDATE_MAIN_ID: "/admin/update-main-id",
    ALL_STACK_LIST: "/admin/stacking-list",
    ACCEPT_REJECT_WITHDRAW_REQUEST: "/admin/action-withdraw",
    GET_DEPOSIT: "/admin/get-deposit",
    GET_USER_DATA: "/admin/user-data",
    GET_USER_WITHDRAW: "/admin/user-withdraw",
    GET_SOCIAL_MEDIA_POST_LIST: "/admin/get-social-media",
    ACTION_ON_SOCIAL_MEDIA: "/admin/action-on-social-media",
    GET_ACTIVE_USER: "/admin/get-active-user",
    GET_EXCHANGE_WITHDRAW: "/admin/get-exchange-withdraw",
    GET_MINING_WITHDRAW: "/admin/get-mining-withdraw",
    SEND_FBC: "/admin/send-fbc"
  },
  User: {
    // RegisterUser: "user/signup-user",
    // EmailVerify: "user/email-verification",
    USER_FORGOT_PASSWORD: "/user/forgot-password",
    USER_RESET_PASSWORD: "/user/reset-password",
    GET_USER_REFERREL_LIST: "/user/get-referral-list",
    GET_MAIN_REFERREL_LIST: "/user/main-referral-list",
    GET_TEAM_REFERREL_LIST: "/user/referral-team",
    USER_OTP: "/user/verify-otp",
    RESEND_OTP: "/user/resend-otp",
    // UserLogin: "user/login",
    // editUserProfile: "/user/edit-user",
    USER_CHANGE_PASSWORD: "/user/change-password",
    GET_WAITLIST_USER: "/admin/get-private-sale-user",
    JOIN_WAITLIST: "user/sign-up",
    USER_LOGIN: "/user/login",
    GET_SETTING: "/user/get-setting",
    GET_REFERRAL_COUNT: "/user/referral-count",
    VERIFY_TRANSACTION: "/user/deposit",
    GET_USER: "/user/details",
    GET_TREE_DATA: "/get-data",
    GET_DASHBOARD: "/user/dashboard",
    GET_REFERRAL_LIST: "/user/referral-list",
    GET_TOTAL_REFERRAL: "user/referral-total",
    USER_PROFILE: "/user/edit-profile",
    USER_MAIN_REFERRAL: "/user/main-referral",
    ADD_EDIT_KYC: "/user/add-edit-kyc",
    KYC_DETAILS: "/user/kyc-details",
    DIRECT_REFERR_USER_LIST: "/user/direct-refer-user",
    GET_STACKING_LIST: "/user/stacking-data",
    STACK_INCOME_LIST: "/user/stack-data",
    GET_TRANSCATION_LIST: "/user/get-transactions",

    KYC_UPLOAD: "/user/add-edit-kyc",
    USER_GET_WITHDRAW_LIST: "user/withdraw-history",
    USER_GET_SWAP_LIST: "trading-list",

    INCOME_DETAILS: "user/get-income",
    GET_STACKING_LIST_DETAILS: "user/staking-details",

    WITHDRAW_VERIFY_USER: `user/verify-user`,
    MINING: "user/get-stake-income",
    Withdraw_user: "/user/withdraw",
    WITHDRAW_NETWORK: "/user/withdraw-network",
    USER_WALLET: "/user/wallet",
    STACK_AMOUNT: "/user/stake-amount",
    POST_SOCIAL_MEDIA: "/user/post-social-media",
    GET_SOCIAL_MEDIA_USER: "/user/get-social-media-user",
    GET_SOCIAL_MEDIA_LINK: "/user/social-media",
    GET_SOCIAL_MEDIA_USER_LIST: "/user/get-social-media",
  },
  Common: {
    PRESALE_DATA: "/get-private-sale-user",
  },
  Exchange: {
    WITHDRAW_CRYPTO: "/withdraw-crypto",
    GET_WALLET: "/get-wallet",
    SWAP_TOKEN: "/swap-token",
    TRANSFER_WITHDRAW: `/withdraw-user`,
  },
};
export { API };
