import usericon from "./images/png/user.png";
// import logo from './images/png/logo.png';
import edit from "./images/png/edit.png";
import logout from "./images/png/logout.png";
import setting from "./images/png/setting.png";
import profile from "./images/png/profile.png";
import footerplaystorescanner from "./images/png/footer-playstore-scanner.png";
import lightLogo from "./images/png/logo.png";
// import herobg from '../assets/images/png/hero-bg.png'

import joyfulbg from "./images/png/joyful-bg.png";
import roadmapbg from "./images/png/roadmapbg.png";
import refrralmobile from "./images/png/refrralmobile.png";
import footerbg from "./images/png/footerbg.png";
import loginbg from "./images/png/loginbg.png";
import signupbg from "./images/png/signupbg.png";
import menuburger from "./images/png/menu-burger.png";
import herobg from "./images/png/1239296.png";
import logo_new from "./images/png/logo_new.png";
import bgimg from "./images/png/bgimg.png";
import closeicon from "./images/png/closeicon.png";

import cancel from "./images/png/cancel.png";
import upload from "./images/png/upload.png";
import close from "./images/png/close.png";
import fbcLogo from "./images/png/FBC_Logo.png";
import nonDepositIc from "./images/png/nonDepositIc.png";
import joiningIc from "./images/png/joiningIc.png";
import transactionIc from "./images/png/transactionIc.png";
import purchaseIc from "./images/png/purchaseIc.png";
import token from "./images/png/token.png";
import depositeUserIc from "./images/png/depositeUserIc.png";
import analysisChart from "./images/png/analysisChart.png";
import presaleBox from "./images/png/presaleBox.png";
import prg from "./images/png/prg.png";
import logVector from "./images/png/logVector.png";
import forgotImg from "./images/png/forgot-img.png";
import bgFbcLogo from "./images/png/bgFbcLogo.png";
import comingsoon from "./images/png/comingsoon.png";
import FBC_NEW_LOGO from "./images/png/FBC_New_logo.png";
import fbcNetworkLogo from "../assets/images/png/FBC_Logo.png";
import Checked from "../assets/images/png/Checked.png";
const Png = {
  fbcNetworkLogo,
  logo_new,
  usericon,
  lightLogo,
  comingsoon,
  // logo,
  herobg,
  joyfulbg,
  roadmapbg,
  refrralmobile,
  footerbg,
  loginbg,
  signupbg,
  menuburger,
  edit,
  setting,
  logout,
  profile,
  footerplaystorescanner,
  bgimg,
  closeicon,
  cancel,
  upload,
  close,
  fbcLogo,
  nonDepositIc,
  joiningIc,
  transactionIc,
  purchaseIc,
  token,
  depositeUserIc,
  analysisChart,
  presaleBox,
  prg,
  logVector,
  forgotImg,
  bgFbcLogo,
  FBC_NEW_LOGO,
  Checked,
};

export default Png;
